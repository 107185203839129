import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-decalogo-seguridad',
  templateUrl: './decalogo-seguridad.component.html',
  styleUrls: ['./decalogo-seguridad.component.scss']
})
export class DecalogoSeguridadComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
