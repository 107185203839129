import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mando-universal-wifi',
  templateUrl: './mando-universal-wifi.component.html',
  styleUrls: ['./mando-universal-wifi.component.scss']
})
export class MandoUniversalWifiComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
