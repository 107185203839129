<P></P>
<div class="container" align="center">
    <h1>Como enlazar el Beok BOT-313 Wifi con google home o alexa</h1>
    <p>
        <a href="https://www.amazon.es/dp/B076BRZ4VV/ref=as_sl_pc_as_ss_li_til?tag=tecnologiad0c-21&linkCode=w00&linkId=7f19055b3f48c63dac2ecd3f6f13088b&creativeASIN=B076BRZ4VV"> <img src="/assets/images/Blog/BeokGoogleAlexa/Beok.jpg" border="0" class="img-fluid"></a>
    </p>
    <p>
        Como montar el termostato wifi ya os lo he explicado en post anteriores, ahora me quiero centrar en como vincular los asistentes de voz google home o alexa. Para directamente en lugar de ir a la aplicación Beok para enchufar la calefacción o subir la
        temperatura de la misma. Puedes directamente decir "Alexa enchufa la calefacción" y esta se enchufe.
    </p>
    <p>
        Partiendo de la base que tenemos el termostato funcionando con el programa de Beok, lo siguiente que hemos de hacer es instalar otra aplicación más en el móvil.

    </p>
    <p>
        <strong>SpeakerAssistant</strong>>
    </p>

    <p>
        <a href="https://play.google.com/store/apps/details?id=com.beok.heat.speakersAssistant&hl=es&gl=US"> <img src="/assets/images/Blog/BeokGoogleAlexa/SpeakerAssistant.jpg" border="0" class="img-fluid"></a>
    </p>
    <p>
        Una vez instalada, cuando la abrimos por primera vez nos pide los permisos, le vamos diciendo que sí. Nos registramos como usuarios de la aplicación.
    </p>
    <div class="row">
        <div class="col-3">
            <img src="/assets/images/Blog/BeokGoogleAlexa/1.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/BeokGoogleAlexa/2.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/BeokGoogleAlexa/3-1.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/BeokGoogleAlexa/3-2.jpg" border="0" class="img-fluid">
        </div>
    </div>
    <p>
        Una vez registrados, entramos a la aplicación con nuestro usuario y contraseña. Y procedemos a registrar el termostato.
    </p>
    <div class="row">
        <div class="col-3">
            <img src="/assets/images/Blog/BeokGoogleAlexa/4-2.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/BeokGoogleAlexa/5.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/BeokGoogleAlexa/6.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/BeokGoogleAlexa/7.jpg" border="0" class="img-fluid">
        </div>
    </div>
    <div class="row">
        <div class="col-3">
            <img src="/assets/images/Blog/BeokGoogleAlexa/8.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/BeokGoogleAlexa/9.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/BeokGoogleAlexa/10.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/BeokGoogleAlexa/11.jpg" border="0" class="img-fluid">
        </div>
    </div>
    <P>
        Ahora ya tenemos el termostato vinculado en la aplicación Speaker Assistant. Ahora solo nos faltara vincular el termostato con Google Home o Alexa, o los dos según prefiramos.
    </P>
    <p>
        <strong>Google Home</strong>>
    </p>
    <div class="row">
        <div class="col-3">
            <img src="/assets/images/Blog/BeokGoogleAlexa/1Google.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/BeokGoogleAlexa/2Google.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/BeokGoogleAlexa/3Google.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/BeokGoogleAlexa/4Google.jpg" border="0" class="img-fluid">
        </div>
    </div>
    <div class="row">
        <div class="col-3">
            <img src="/assets/images/Blog/BeokGoogleAlexa/5Google.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/BeokGoogleAlexa/6Google.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/BeokGoogleAlexa/7Google.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/BeokGoogleAlexa/8Google.jpg" border="0" class="img-fluid">
        </div>
    </div>
    <div class="row">
        <div class="col-3">
            <img src="/assets/images/Blog/BeokGoogleAlexa/9Google.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/BeokGoogleAlexa/10Google.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/BeokGoogleAlexa/11Google.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/BeokGoogleAlexa/12Google.jpg" border="0" class="img-fluid">
        </div>
    </div>
    <div class="row">
        <div class="col-3">
            <img src="/assets/images/Blog/BeokGoogleAlexa/13Google.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/BeokGoogleAlexa/14Google.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/BeokGoogleAlexa/14Google-1.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/BeokGoogleAlexa/15Google.jpg" border="0" class="img-fluid">
        </div>
    </div>
    <p>
        Ahora ya podremos decirle a google "ok google enchufa la calefacción" o "ok google pon la calefacción a 22 grados".
    </p>
    <p>Ahora vamos a proceder a configurar Alexa</p>
    <p><strong>Alexa</strong></p>
    <div class="row">
        <div class="col-3">
            <img src="/assets/images/Blog/BeokGoogleAlexa/Alexa1.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/BeokGoogleAlexa/Alexa2.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/BeokGoogleAlexa/Alexa3.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/BeokGoogleAlexa/Alexa4.jpg" border="0" class="img-fluid">
        </div>
    </div>
    <div class="row">
        <div class="col-3">
            <img src="/assets/images/Blog/BeokGoogleAlexa/Alexa5.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/BeokGoogleAlexa/Alexa6.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/BeokGoogleAlexa/Alexa7.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/BeokGoogleAlexa/Alexa8.jpg" border="0" class="img-fluid">
        </div>
    </div>
    <div class="row">
        <div class="col-3">
            <img src="/assets/images/Blog/BeokGoogleAlexa/Alexa9.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/BeokGoogleAlexa/Alexa10.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/BeokGoogleAlexa/Alexa11.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/BeokGoogleAlexa/Alexa12.jpg" border="0" class="img-fluid">
        </div>
    </div>
    <div class="row">
        <div class="col-3">
            <img src="/assets/images/Blog/BeokGoogleAlexa/Alexa13.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/BeokGoogleAlexa/Alexa15.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/BeokGoogleAlexa/Alexa16.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/BeokGoogleAlexa/Alexa17.jpg" border="0" class="img-fluid">
        </div>
    </div>
    <p>
        Ahora ya podemos decirle a Alexa. "Alexa enchufa la calefacción" o "Alexa pon la calefacción a 22 grados".
    </p>
    <p>
        Espero haber sido de utilidad y hayáis podido ver la compatibilidad de Beok con los demás dispositivos.
    </p>
    <div class="row">
        <div class="col-6">
            <a href="https://www.amazon.es/dp/B076BRZ4VV/ref=as_sl_pc_as_ss_li_til?tag=tecnologiad0c-21&linkCode=w00&linkId=7f19055b3f48c63dac2ecd3f6f13088b&creativeASIN=B076BRZ4VV"> <img src="/assets/images/Blog/BeokGoogleAlexa/Beok-1.jpg" border="0" class="img-fluid"></a>
        </div>
        <div class="col-6">
            <a href="https://amzn.to/3oRzngP"> <img src="/assets/images/Blog/BeokGoogleAlexa/amazonEcho.jpg" border="0" class="img-fluid"></a>

        </div>

    </div>
</div>