<P></P>
<div class="container" align="center">
    <h1>Broadlink mando universal, como crear un mando desde cero.</h1>
    <p>
        <a href="https://amzn.to/2LRMWPr"> <img src="/assets/images/Blog/MandoDesdeCero/broadlink.jpg" border="0" class="img-fluid"></a>
    </p>
    <p>
        El mando universal broadlink tiene la opción de configurar un mando ya configurado previamente tal y como os he explicado en posts anteriores.
    </p>
    <p>
        Pero también tiene la opción de crear un mando desde cero, puedes ir creando cada uno de los botones del mando.
    </p>
    <p>
        A continuación os hago una pequeña explicación de como crear un mando de cero.
    </p>
    <div class="row">
        <div class="col-3">
            <img src="/assets/images/Blog/MandoDesdeCero/1p.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/MandoDesdeCero/2p.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/MandoDesdeCero/3p.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            Selecciona tu broadlink
            <img src="/assets/images/Blog/MandoDesdeCero/4p.jpg" border="0" class="img-fluid">
        </div>
    </div>
    <div class="row">
        <div class="col-3">
            <img src="/assets/images/Blog/MandoDesdeCero/5p.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/MandoDesdeCero/6p.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            Selecciona donde esta tu dispositivo
            <img src="/assets/images/Blog/MandoDesdeCero/7p.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/MandoDesdeCero/8p.jpg" border="0" class="img-fluid">
        </div>
    </div>
    <div class="row">
        <div class="col-3">
            <img src="/assets/images/Blog/MandoDesdeCero/9p.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/MandoDesdeCero/10p.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/MandoDesdeCero/11p.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/MandoDesdeCero/12p.jpg" border="0" class="img-fluid">
        </div>
    </div>
    <div class="row">
        <div class="col-3">
            Elige tipo de mando infrarojos o radiofrecuencia
            <img src="/assets/images/Blog/MandoDesdeCero/13p.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/MandoDesdeCero/14p.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            Apunta con el mando al broadlink y aprieta el botón. Para que broadlink aprenda.
            <img src="/assets/images/Blog/MandoDesdeCero/15p.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            Aprieta el botón y comprueba que hace la función
            <img src="/assets/images/Blog/MandoDesdeCero/16p.jpg" border="0" class="img-fluid">
        </div>
    </div>
    <div class="row">
        <div class="col-3">
            Si ha funcionado correctamente. Yes
            <img src="/assets/images/Blog/MandoDesdeCero/17p.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/MandoDesdeCero/18p.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/MandoDesdeCero/19p.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            Podemos elegir el botón que queramos o hacer una foto al botón.
            <img src="/assets/images/Blog/MandoDesdeCero/20p.jpg" border="0" class="img-fluid">
        </div>
    </div>
    <div class="row">
        <div class="col-3">
            Voy a hacer la foto al botón. Haremos clic en el botón de la cámara de arriba a la derecha.
            <img src="/assets/images/Blog/MandoDesdeCero/21p.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/MandoDesdeCero/22p.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/MandoDesdeCero/23p.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            Hacemos la foto
            <img src="/assets/images/Blog/MandoDesdeCero/24p.jpg" border="0" class="img-fluid">
        </div>
    </div>
    <div class="row">
        <div class="col-3">
            <img src="/assets/images/Blog/MandoDesdeCero/25p.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            Elige la aplicación para recortar la imagen.
            <img src="/assets/images/Blog/MandoDesdeCero/26p.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/MandoDesdeCero/27p.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/MandoDesdeCero/28p.jpg" border="0" class="img-fluid">
        </div>
    </div>
    <div class="row">
        <div class="col-3">
            <img src="/assets/images/Blog/MandoDesdeCero/29p.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">

        </div>
        <div class="col-3">

        </div>
        <div class="col-3">

        </div>
    </div>
    <p>A partir de aquí vamos repitiendo los pasos de añadir botón. Hasta tener todos los botones configurados.</p>
    <p>
        <a href="https://amzn.to/2LRMWPr"> <img src="/assets/images/Blog/MandoDesdeCero/broadlink.jpg" border="0" class="img-fluid"></a>
    </p>
</div>