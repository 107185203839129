import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mando-desde-cero',
  templateUrl: './mando-desde-cero.component.html',
  styleUrls: ['./mando-desde-cero.component.scss']
})
export class MandoDesdeCeroComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
