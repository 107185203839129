import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tira-led',
  templateUrl: './tira-led.component.html',
  styleUrls: ['./tira-led.component.scss']
})
export class TiraLedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
