import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-legales',
  templateUrl: './legales.component.html',
  styleUrls: ['./legales.component.scss']
})
export class LegalesComponent implements OnInit {

  tittle = "Aviso legal y términos de uso";

  constructor() {
  }

  ngOnInit(): void {
  }

}
