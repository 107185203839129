import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ContactComponent} from './components/contact/contact.component';
import {RegisterComponent} from './components/register/register.component';
import {EnvioComponent} from './components/envio/envio.component';
import {PerfilComponent} from './components/perfil/perfil.component';
import {AuthGuard} from "./guards/auth.guard";
import {NonAuthGuard} from "./guards/non-auth.guard";
import {NewPasswordComponent} from "./components/usuarios/new-password/new-password.component";
import {LoginComponent} from "./components/usuarios/login/login.component";
import {ConstructionComponent} from "./components/construction/construction.component";
import {LegalesComponent} from "./components/shared/legales/legales.component";
import {PrivacidadComponent} from "./components/shared/privacidad/privacidad.component";
import {CookiesComponent} from "./components/shared/cookies/cookies.component";
import {DomoticaComponent} from "./components/Blog/domotica/domotica.component";
import {InformaticainicialComponent} from "./components/Blog/informaticainicial/informaticainicial.component";
import {SistemasComponent} from "./components/Blog/sistemas/sistemas.component";
import {DecalogoSeguridadComponent} from "./components/Blog/sistemas/decalogo-seguridad/decalogo-seguridad.component";
import {VhsADigitalComponent} from "./components/Blog/informaticainicial/vhs-adigital/vhs-adigital.component";
import {OrdenadorLentoComponent} from "./components/Blog/informaticainicial/ordenador-lento/ordenador-lento.component";
import {AmazonPhotosComponent} from "./components/Blog/informaticainicial/amazon-photos/amazon-photos.component";
import {EspacioInsuficienteComponent} from "./components/Blog/informaticainicial/espacio-insuficiente/espacio-insuficiente.component";
import {EnchufeWifiComponent} from "./components/Blog/domotica/enchufe-wifi/enchufe-wifi.component";
import {MandoDesdeCeroComponent} from "./components/Blog/domotica/mando-desde-cero/mando-desde-cero.component";
import {MandoUniversalWifiComponent} from "./components/Blog/domotica/mando-universal-wifi/mando-universal-wifi.component";
import {BeokGoogleAlexaComponent} from "./components/Blog/domotica/beok-google-alexa/beok-google-alexa.component";
import {CalefaccionDomotizadaComponent} from "./components/Blog/domotica/calefaccion-domotizada/calefaccion-domotizada.component";
import {BeokWifiComponent} from "./components/Blog/domotica/beok-wifi/beok-wifi.component";
import {TiraLedComponent} from "./components/Blog/domotica/tira-led/tira-led.component";
import {ServiciosComponent} from "./components/servicios/servicios.component";
import {HomeComponent} from "./components/home/home.component";




const routes: Routes = [

  {path: '', redirectTo: '/home', pathMatch: 'full'},
  {path: 'construction', component: ConstructionComponent},
  {path: 'contact', component: ContactComponent},
  {path: 'login', component: LoginComponent},
  {path: 'usuarios/reset/:token', component: NewPasswordComponent},
  {path: 'register', canActivate: [NonAuthGuard], component: RegisterComponent},
  {path: 'perfil', component: PerfilComponent, canActivate: [AuthGuard]},
  {path: 'ayuda', component: EnvioComponent},
  {path: 'aviso_legal', component: LegalesComponent},
  {path: 'privacidad', component: PrivacidadComponent},
  {path: 'cookies', component: CookiesComponent},
  {path: 'domotica', component: DomoticaComponent},
  {path: 'informaticainicial', component: InformaticainicialComponent},
  {path: 'sistemas', component: SistemasComponent},
  {path: 'servicios', component: ServiciosComponent},
  {path: 'home', component: HomeComponent},
  {path: 'enchufe', component: EnchufeWifiComponent},
  {path: 'mandocero', component: MandoDesdeCeroComponent},
  {path: 'mandouniversalwifi', component: MandoUniversalWifiComponent},
  {path: 'beokgooglealexa', component: BeokGoogleAlexaComponent},
  {path: 'vhsadigital', component: VhsADigitalComponent},
  {path: 'ordenadorlento', component: OrdenadorLentoComponent},
  {path: 'amazonphotos', component: AmazonPhotosComponent},
  {path: 'espacioinsuficiente', component: EspacioInsuficienteComponent},
  {path: 'tiraled', component: TiraLedComponent},
  {path: 'beokwifi', component: BeokWifiComponent},
  {path: 'calefacciondomotizada', component: CalefaccionDomotizadaComponent},
  {path: 'decalogoseguridad', component: DecalogoSeguridadComponent},
  {path: '**', redirectTo: '/home', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
