export class Usuario {
  id?: number;
  password: string;
  nombre: string;
  telf: string;
  email: string;
  role?: string;
  direccion: string;
  ciudad: string;
  provincia: string;
  cp: number;
}
