import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Contact} from '../entities/Contact';
import {environment} from '../../environments/environment';
import {Msg} from '../entities/Msg';

@Injectable({
  providedIn: 'root'
})
export class ContactService {


  constructor(private http: HttpClient) {
  }

  createContact(contact: Contact) {
    const urlEndpoint = environment.backend + '/info';
    console.log(contact);
    let data ={
      user:contact.name,
      mail:contact.mail,
      msg:contact.msg
    };

    return this.http.post<any>(urlEndpoint, data);
  }
}
