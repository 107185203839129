<div class="container">
    <form [formGroup]="form" (ngSubmit)="register()" id="contact-form" method="post" role="form" class="animated fadeIn fast">
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <span class="col-1 col-md-offset-2 text-center"><i class="fa fa-user bigicon"></i></span>
                    <label for="form_name">Nombre Completo</label>
                    <input formControlName="name" type="text" class="form-control" id="form_name" placeholder="Nombre Completo">
                    <div *ngIf="f.name.invalid && (f.name.dirty || f.name.touched)" class="alert alert-danger col-12">

                        <div *ngIf="f.name.errors.required">
                            Debes introducir tu nombre completo.
                        </div>
                        <div *ngIf="f.name.errors.minlength">
                            El nombre debe de tener como mínimo 3 letras.
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <span class="col-1 col-md-offset-2 text-center"><i class="fa fa-phone bigicon"></i></span>
                    <label for="form_telf">Teléfono</label>
                    <input formControlName="telefono" type="text" class="form-control" id="form_telf" placeholder="611 063 564" required minlength="9">
                    <div *ngIf="f.telefono.invalid && (f.telefono.touched)" class="alert alert-danger col-12">
                        <div *ngIf="f.telefono.errors.minlength">
                            El número de teléfono debe de tener como mínimo 9 digitos.
                        </div>
                        <div *ngIf="f.telefono.errors.required">
                            Debes introducir tú número de teléfono
                        </div>
                        <div *ngIf="f.telefono.errors.pattern">
                            El formato del número de teléfono introducido es incorrecto.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group" formGroupName="emails">
                    <div class="input-group mb-3">
                        <span class="col-1 col-md-offset-2 text-center"><i class="fa fa-envelope-o bigicon"></i></span>
                        <input formControlName="email" id="form_email" type="email" class="form-control" placeholder="Dirección email">
                        <div *ngIf="this.form.get(['emails','email']).touched && this.form.get(['emails','email']).invalid" class="alert alert-danger col-12">
                            <div *ngIf="this.form.get(['emails','email']).errors.required">Correo electrónico obligatorio.</div>
                            <div *ngIf="this.form.get(['emails','email']).errors.pattern">El formato del correo electrónico introducido es incorrecto.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group" formGroupName="emails">
                    <div class="input-group mb-3">
                        <span class="col-1 col-md-offset-2 text-center"><i class="fa fa-envelope-o bigicon"></i></span>
                        <input formControlName="confirm_email" id="form_email_validation" type="email" name="email" class="form-control" placeholder="Repite Dirección e-mail">
                        <div *ngIf="(this.form.get(['emails','confirm_email']).touched || this.form.get(['emails','confirm_email']).dirty) && this.form.get(['emails','confirm_email']).invalid" class="alert alert-danger col-12">
                            <div *ngIf="this.form.get(['emails','confirm_email']).errors.required">Correo electrónico obligatorio.
                            </div>
                            <div *ngIf="this.form.get(['emails','confirm_email']).errors.pattern">El formato del correo electrónico introducido es incorrecto.
                            </div>
                            <div *ngIf="this.form.get(['emails','confirm_email']).errors.check">
                                Los correos electrónicos no coinciden.
                            </div>
                            <!--<div *ngIf="f.confirm_email.errors.check">Las direcciones de correo electrónico no coinciden.</div>-->
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group" formGroupName="passwords">
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="col-1 col-md-offset-2 text-center"><i class="fa fa-key bigicon"></i></span>
                        </div>
                        <input formControlName="password" id="password" type="password" class="form-control" autocomplete="new-password" placeholder="Contraseña">
                        <div *ngIf="(this.form.get(['passwords','password']).touched || this.form.get(['passwords','password']).dirty) && this.form.get(['passwords','password']).invalid" class="alert alert-danger col-12">
                            <div *ngIf="this.form.get(['passwords','password']).errors.required">Contraseña obligatoria.</div>
                        </div>
                    </div>
                </div>
                <div class="form-group" formGroupName="passwords">
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="col-1 col-md-offset-2 text-center"><i class="fa fa-key bigicon"></i></span>
                        </div>
                        <input formControlName="confirm_password" id="confirm_password" type="password" class="form-control" autocomplete="new-password" placeholder="Repite la contraseña">
                        <div *ngIf="this.form.get(['passwords','confirm_password']).touched && this.form.get(['passwords','confirm_password']).invalid" class="alert alert-danger col-12">
                            <div *ngIf="this.form.get(['passwords','confirm_password']).errors.required">Contraseña obligatoria.</div>
                            <div *ngIf="this.form.get(['passwords','password']).value != this.form.get(['passwords','confirm_password']).value && this.form.get(['passwords','confirm_password']).value != null">
                                Las contraseñas no coinciden.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <span class="col-1 col-md-offset-2 text-center"><i class="fa fa-map bigicon"></i></span>
                    <label for="form_direccion">Dirección</label>
                    <input formControlName="direccion" id="form_direccion" type="text" name="direccion" class="form-control" placeholder="Dirección completa">
                    <div *ngIf="f.direccion.invalid && (f.direccion.dirty || f.direccion.touched)" class="alert alert-danger col-12">
                        <div *ngIf="f.direccion.errors.required">
                            Debes introducir tu dirección postal.
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <span class="col-1 col-md-offset-2 text-center"><i class="fa fa-map bigicon"></i></span>
                    <label for="form_estado">Estado/Provincia/Región</label>
                    <input formControlName="provincia" id="form_estado" type="text" name="estado" class="form-control" placeholder="Provincia">
                    <div *ngIf="f.provincia.invalid && (f.provincia.dirty || f.provincia.touched)" class="alert alert-danger col-12">
                        <div *ngIf="f.provincia.errors.required">
                            Debes introducir tu provincia.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <span class="col-1 col-md-offset-2 text-center"><i class="fa fa-map bigicon"></i></span>
                    <label for="form_ciudad">Ciudad</label>
                    <input formControlName="ciudad" id="form_ciudad" type="text" name="ciudad" class="form-control" placeholder="Ciudad">
                    <div *ngIf="f.ciudad.invalid && (f.ciudad.dirty || f.ciudad.touched)" class="alert alert-danger col-12">
                        <div *ngIf="f.ciudad.errors.required">
                            Debes introducir tu ciudad.
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <span class="col-1 col-md-offset-2 text-center"><i class="fa fa-map bigicon"></i></span>
                    <label for="form_postal">Código Postal</label>
                    <input formControlName="cp" id="form_postal" type="number" name="cp" class="form-control" placeholder="Código postal">
                    <div *ngIf="f.cp.invalid && (f.cp.dirty || f.cp.touched)" class="alert alert-danger col-12">
                        <div *ngIf="f.cp.errors.required">
                            Debes introducir tu código postal.
                        </div>
                        <div *ngIf="f.cp.errors.minlength">
                            El código postal debe de tener 5 digitos.
                        </div>
                        <div *ngIf="f.cp.errors.maxlength">
                            El código postal debe de tener 5 digitos.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 text-right">
                <div class="form-group form-check">
                    <input type="checkbox" formControlName="acceptTerms" id="acceptTerms" class="form-check-input" (change)="onCheckChange($event)" [ngClass]="{ 'is-invalid': !submitted }" />
                    <label for="acceptTerms" class="form-check-label">He leído y acepto la <a routerLink="/privacidad" target="_blank">política de
            privacidad</a></label>
                    <div *ngIf="!submitted" class="invalid-feedback">Es necesario leer y aceptar la política de privicidad
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-12 text-right">
                <input type="submit" class="btn btn-info btn-send" value="Registrarse">
            </div>
        </div>
    </form>
</div>