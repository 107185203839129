<P></P>
<div class="container" align="center">
    <h1>VHS a Digital</h1>
    <p>
        Tienes películas en formato VHS o DVD, pásalas a formato digital, aun estas a tiempo.
        <br>Aun debes de tener por casa algún reproductor VHS funcionando, cuando ya no tengas ningún reproductor de VHS ya no tendrás la oportunidad. A no ser que vayas a un sitio especializado y te cobren por ello.
        <BR>Por norma los sitios que se dedican a pasar tus películas a formato digital cobran por horas de reproducción. Por ejemplo, a 15€ la hora de reproducción. Si tienes una cinta VHS de 4 horas te cobraran 60€ por pasar una película.
        <br>Uno de los inconvenientes de pasar las películas a digital, es que ha de estar reproduciendo todo el rato la cinta para irlas pasando al ordenador, de ahí el precio que tiene hacer la transformación. A continuación, voy a intentar haceros
        una guía de como debéis hacer para pasar vuestros videos a formato digital.
    </p>
    <p>
        Material Necesario:
    </p>
    <p>
        - Capturadora de video: A continuación, os dejo un enlace de una posible capturadora, es la que yo he utilizado y me ha funcionado correctamente.
    </p>
    <p>
        - Reproductor VHS, o la Cámara de video que contenga los videos, o el medio necesario para reproducir los videos que quieras pasar
    </p>
    <p>
        - Un ordenador con espacio suficiente para pasar los videos
    </p>
    <p>
        La ultima capturadora que utilice en mi ultimo proyecto fue la woxter:
    </p>
    <p>
        <img src="/assets/images/Blog/VhsADigital/woxter.jpg" border="0" class="img-fluid">

    </p>
    <p>
        Lo primero que tenemos que hacer es instalar los drivers de la capturadora
    </p>
    <p>
        <img src="/assets/images/Blog/VhsADigital/woxter2.jpg" border="0" class="img-fluid">
    </p>
    <p>
        A continuación, procedemos a instalar el programa necesario para las capturas.
    </p>
    <p>
        En la misma capturadora viene con un cd, con el software necesario para poder hacer las capturas. Es muy fácil la instalación siguiente, ... finalizar.
    </p>
    <div class="row">
        <div class="col-3">
            <img src="/assets/images/Blog/VhsADigital/Install.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/VhsADigital/Install1.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/VhsADigital/Install2.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/VhsADigital/Install3.jpg" border="0" class="img-fluid">
        </div>
    </div>
    <p>
        Al ejecutar el programa tenemos la opcion de modificar la carpeta donde se guardan las capturas de video.
    </p>
    <p>
        <img src="/assets/images/Blog/VhsADigital/EZGrabber2-273x300.jpg" border="0" class="img-fluid">
    </p>
    <p>
        Ahora ya simplemente si conectamos el reproductor de video a la capturadora y le damos al play deberíamos verlo en el ordenador, en esta pantalla negra que os muestro en la imagen.
        <br>Y ahora ya es solo darle al botón de grabar y empezaremos a tener nuestros videos en formato digital.
    </p>
    <p>
        <img src="/assets/images/Blog/VhsADigital/grabar-300x294.jpg" border="0" class="img-fluid">
    </p>
    <p>
        De una manera fácil podemos tener todos nuestros videos digitalizados. Es fácil lo único es que lleva bastante tiempo, ya que como os he comentado tenéis que estar reproduciendo los videos para que la capturadora los pueda ir grabando en el ordenador.
    </p>
    <p>
        Espero os haya servido como guia para no dar como perdidos vuestros videos viejos, o las grabaciones hechas con vuestras antiguas camaras de video.
    </p>
</div>