import {BrowserModule} from '@angular/platform-browser';
import {registerLocaleData} from '@angular/common';
import localeEs from '@angular/common/locales/es';
import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatButtonModule, MatDialogModule} from '@angular/material';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {NgxSpinnerModule} from 'ngx-spinner';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {AppComponent} from './app.component';
import {NavbarComponent} from './components/shared/navbar/navbar.component';
import {FooterComponent} from './components/shared/footer/footer.component';
import {ContactComponent} from './components/contact/contact.component';
import {ConstructionComponent} from './components/shared/construction/construction.component';
import {RegisterComponent} from './components/register/register.component';
import {EnvioComponent} from './components/envio/envio.component';
import {PerfilComponent} from './components/perfil/perfil.component';
import {NewPasswordComponent} from "./components/usuarios/new-password/new-password.component";
import {AuthInterceptorService} from "./services/auth-interceptor.service";
import {AppRoutingModule} from './app-routing.module';
import {LoginComponent} from "./components/usuarios/login/login.component";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import { LegalesComponent } from './components/shared/legales/legales.component';
import { CookiesComponent } from './components/shared/cookies/cookies.component';
import { PrivacidadComponent } from './components/shared/privacidad/privacidad.component';
import { DomoticaComponent } from './components/Blog/domotica/domotica.component';
import { EnchufeWifiComponent } from './components/Blog/domotica/enchufe-wifi/enchufe-wifi.component';
import { SistemasComponent } from './components/Blog/sistemas/sistemas.component';
import { InformaticainicialComponent } from './components/Blog/informaticainicial/informaticainicial.component';
import { ServiciosComponent } from './components/servicios/servicios.component';
import { HomeComponent } from './components/home/home.component';
import { MandoDesdeCeroComponent } from './components/Blog/domotica/mando-desde-cero/mando-desde-cero.component';
import { MandoUniversalWifiComponent } from './components/Blog/domotica/mando-universal-wifi/mando-universal-wifi.component';
import { TiraLedComponent } from './components/Blog/domotica/tira-led/tira-led.component';
import { BeokWifiComponent } from './components/Blog/domotica/beok-wifi/beok-wifi.component';
import { VhsADigitalComponent } from './components/Blog/informaticainicial/vhs-adigital/vhs-adigital.component';
import { OrdenadorLentoComponent } from './components/Blog/informaticainicial/ordenador-lento/ordenador-lento.component';
import { AmazonPhotosComponent } from './components/Blog/informaticainicial/amazon-photos/amazon-photos.component';
import { EspacioInsuficienteComponent } from './components/Blog/informaticainicial/espacio-insuficiente/espacio-insuficiente.component';
import { DecalogoSeguridadComponent } from './components/Blog/sistemas/decalogo-seguridad/decalogo-seguridad.component';
import { BeokGoogleAlexaComponent } from './components/Blog/domotica/beok-google-alexa/beok-google-alexa.component';
import { CalefaccionDomotizadaComponent } from './components/Blog/domotica/calefaccion-domotizada/calefaccion-domotizada.component';
registerLocaleData(localeEs, 'es');
@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    ContactComponent,    
    LoginComponent,    
    ConstructionComponent,
    RegisterComponent,
    EnvioComponent,      
    PerfilComponent,    
    NewPasswordComponent,
    LegalesComponent,
    CookiesComponent,
    PrivacidadComponent,    
    DomoticaComponent,
    EnchufeWifiComponent,
    SistemasComponent,
    InformaticainicialComponent,
    ServiciosComponent,
    HomeComponent,
    MandoDesdeCeroComponent,    
    MandoUniversalWifiComponent,
    TiraLedComponent,
    BeokWifiComponent,
    VhsADigitalComponent,
    OrdenadorLentoComponent,
    AmazonPhotosComponent,
    EspacioInsuficienteComponent,
    DecalogoSeguridadComponent,
    BeokGoogleAlexaComponent,
    CalefaccionDomotizadaComponent,
  ],
    imports: [
        HttpClientModule,
        BrowserModule,
        AppRoutingModule,
        SweetAlert2Module.forRoot(),
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        MatDialogModule,
        MatButtonModule,
        MatSelectModule,
        MatInputModule,
        NgxSpinnerModule,
        FontAwesomeModule

    ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true},
    {provide: LOCALE_ID, useValue: 'es'},
  ],  
  bootstrap: [AppComponent]
})
export class AppModule {
}
