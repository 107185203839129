<ngx-spinner></ngx-spinner>


<div class="container">
    <p></p>
    <p align="center">
        <img src="/assets/images/fotoyo.jpeg" border="0" width="10%" class="animate__animated animate__backInUp">
    </p>
    




    <p align="center">¡Bienvenido a mi página web de contacto! Soy un profesional independiente de informática con experiencia en una amplia variedad de áreas de la informática. Si necesitas ayuda con cualquier tipo de proyecto informático, desde la reparación de equipos hasta el desarrollo de software personalizado, estoy aquí para ayudarte.</p>
    <p align="center">Mi objetivo es proporcionarte un servicio excepcional que satisfaga tus necesidades y supere tus expectativas. Como profesional independiente, puedo ofrecerte una atención personalizada y un enfoque individualizado para abordar tus problemas y resolverlos de manera efectiva.</p>
    <p align="center">Si tienes una consulta o simplemente deseas obtener más información sobre los servicios que ofrezco, no dudes en ponerte en contacto conmigo. Estoy disponible para responder cualquier pregunta que puedas tener y trabajar contigo para encontrar la solución adecuada para tus necesidades.</p>
    <p align="center">¡Gracias por visitar mi página web de contacto! Espero tener la oportunidad de trabajar contigo pronto.</p>
    

    <p align="center">
        <a href="mailto:email@manuelmelero.com"><i class="fa fa-envelope-o fa-2x" aria-hidden="true"></i> email@manuelmelero.com</a></p>
    <p align="center">
        <a href="tel:+34649627122"><i class="fa fa-phone fa-2x"></i> +34 649 62 71 22</a></p>

    <p align="center">
        <a target="_blank" href="https://www.facebook.com/manuel.melero.969"><i class="fa fa-facebook-official fa-5x" aria-hidden="true"></i></a>&nbsp;
        <a target="_blank" href="https://www.linkedin.com/in/manuel-melero-olivares-00988223/"><i class="fa fa-linkedin-square fa-5x" aria-hidden="true"></i></a>&nbsp;
        <a target="_blank" href="https://www.instagram.com/manuelmelero_._/?hl=es"><i class="fa fa-instagram fa-5x" aria-hidden="true"></i></a>
    </p>



</div>
<!--
<div class="container">

    <form #contactForm="ngForm" (ngSubmit)="enviarMensaje(contactForm)" id="contact-form" method="post" role="form" class="animated fadeIn fast">
        <div class="messages"></div>
        <div class="controls">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <span class="col-1 col-md-offset-2 text-center"><i class="fa fa-user bigicon"></i></span>
                        <label for="form_name">Nombre </label>
                        <input id="form_name" type="text" name="name" class="form-control" placeholder="Nombre completo" [(ngModel)]="contact.name" required="required" data-error="Porfavor, Introduzca un nombre.">
                        <div class="help-block with-errors"></div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <span class="col-1 col-md-offset-2 text-center"><i class="fa fa-envelope-o bigicon"></i></span>
                        <label for="form_email">E-mail</label>
                        <input id="form_email" type="email" name="email" class="form-control" placeholder="Dirección e-mail" [(ngModel)]="contact.mail" required="required" data-error="Porfavor, Introduzca un email válido.">
                        <div class="help-block with-errors"></div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <span class="col-1 col-md-offset-2 text-center"><i class="fa fa-pencil-square-o bigicon"></i></span>
                        <label for="form_message">Mensaje</label>
                        <textarea id="form_message" name="message" class="form-control" placeholder="Introduce tu mensaje..." rows="4" required="required" data-error="Porfavor, Introduzca un mensaje." [(ngModel)]="contact.msg"></textarea>
                        <div class="help-block with-errors"></div>
                    </div>
                </div>
                <div class="col-md-12" align="center">
                    <input type="submit" class="btn btn-outline-primary btn-send" value="Enviar mensaje">
                </div>
            </div>
        </div>
    </form>

</div>
-->
<p></p>

<div class="container"><br><br><br><br></div>