<P></P>
<div class="container" align="center">
    <h1>Calefacción domotizada</h1>
    <p>
        Empiezo mi primera página del blog, con uno de los objetivos de este, hacer de algo que parece complicado y caro. Hacerlo accesible para todos.
        <bR>La tecnología va avanzando y uno de sus platos fuertes es la domótica, hoy en día han empezado a aparecer unos cuantos termostatos de calefacción domotizados, con wifi incorporado y con su app para el móvil relacionado. Para muchos parecerá una
        tarea cara y complicada pero hoy os voy a dejar una opción fácil, económica y que además nos hará ahorrar dinero, que nos permitirá programar la calefacción de nuestro hogar y tenerla controlada en cualquier momento o lugar.
        <br>El termostato wifi que he elegido para domotizar esta parte de mi casa es el BEOK BOT 313 Wifi, termostato wifi barato que podemos obtener por poco más de 40 o 50€.
    </p>
    <p>
        <iframe marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//rcm-eu.amazon-adsystem.com/e/cm?lt1=_blank&amp;bc1=000000&amp;IS2=1&amp;bg1=FFFFFF&amp;fc1=000000&amp;lc1=0000FF&amp;t=tecnologiad0c-21&amp;language=es_ES&amp;o=30&amp;p=8&amp;l=as4&amp;m=amazon&amp;f=ifr&amp;ref=as_ss_li_til&amp;asins=B076BRZ4VV&amp;linkId=7f19055b3f48c63dac2ecd3f6f13088b"
            style="background-color: transparent; font-size: 12px; width: 120px; height: 240px;"></iframe>
    </p>
    <p>
        Existe un termostato muy parecido a este, pero no lleva wifi y lo podéis encontrar más barato. Tienes dos diferencias importantes. La primera que nos encontramos es la ausencia de wifi y por tanto no tendrá aplicación para el móvil.
        <br>Por otro lado no va conectada a la luz eléctrica de casa y se alimenta con pilas, al no disponer de wifi la duración no se verá lastrada y estará en funcionamiento mucho tiempo.
    </p>
    <p>
        <strong>Instalación y utilización paso a paso</strong>
    </p>
    <p>
        Una vez obtenido el termostato wifi vamos a proceder a substituir nuestro termostato actual por este nuevo que nos permitirá domotizar la calefacción.
        <br>Lo primero que debemos hacer es desmontar nuestro termostato, en mi caso, como en la mayoría, solo llegan dos cables que son los necesarios para activar la caldera. Para enchufar el termostato wifi nos hará falta dos cables más de corriente
        que podremos sacar de algún cajetín o enchufe que tengamos cerca de nuestro termostato.
        <br>A continuación, vamos a ver la parte trasera del termostato wifi, para que veáis donde van enchufados los cables, luego entraremos en algunos detalles de la instalación y aplicación móvil del termostato wifi.
    </p>
    <p>
        <img src="/assets/images/Blog/CalefaccionDomotizada/BeokBot313-WifiT.jpg" border="0" class="img-fluid">

    </p>
    <p>
        Los dos cables que vienen de la caldera van a los puntos 5 y 6 y conectaremos los dos cables de luz que hemos sacado para darle corriente al termostato. El cable azul irá al punto N (con el número 3), el cable marrón o negro irá al punto L (con el número
        4).
        <bR>Con estas conexiones y la posterior colocación del termostato en la pared ya tendremos domotizada la calefacción.
        <br>Con esto y un pequeño truco para anclar el termostato a la pared ya tenemos domotizada la calefacción.
        <br>Al mismo tiempo de comprar el beok, podéis aprovechar para comprar el cajetín para su instalación, anclareis el cajetín a la pared y podréis anclar el termostato wifi al cajetín.
    </p>
    <p>
        <iframe marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//rcm-eu.amazon-adsystem.com/e/cm?lt1=_blank&amp;bc1=000000&amp;IS2=1&amp;bg1=FFFFFF&amp;fc1=000000&amp;lc1=0000FF&amp;t=tecnologiad0c-21&amp;language=es_ES&amp;o=30&amp;p=8&amp;l=as4&amp;m=amazon&amp;f=ifr&amp;ref=as_ss_li_til&amp;asins=B001ZAXBU0&amp;linkId=2a67755230cc3a490481eed527498eea"
            style="background-color: transparent; font-size: 12px; width: 120px; height: 240px;"></iframe>
    </p>
    <p>
        Por último, os voy a enseñar dos pantallazos de aplicación para que veáis lo fácil que resulta parar o encender la calefacción y programar a que temperatura quiero que se enchufe la calefacción.
    </p>
    <p>
        <img src="/assets/images/Blog/CalefaccionDomotizada/PantallazoApp.jpg" border="0" class="img-fluid">

    </p>
    <p>
        Tenemos un sencilla interface con solo las opciones de encender y apagar y control de temperatura o bien podemos entrar en modo automático para programar diariamente, horarios y temperatura de funcionamiento.
        <br>En esta primera interface que es la que suelo utilizar, solo tengo que arrastrar el dedo por la redonda de temperatura para fijar la temperatura a la que quiero que se encienda la calefacción. Esta muestra la temperatura actual de la casa
        y ponemos la temperatura que deseamos.
    </p>
    <p>
        También podemos observar un botón de encendido y apagado abajo a la izquierda. Así de sencilla puede resultar la aplicación y el domotizar nuestra calefacción estemos donde estemos podremos tener el control de la temperatura de nuestro hogar y por un
        coste mínimo de unos 60€ podemos tener la calefacción domotizada.
    </p>
    <p align="center"><iframe style="width:120px;height:240px;" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//rcm-eu.amazon-adsystem.com/e/cm?lt1=_blank&amp;bc1=000000&amp;IS2=1&amp;bg1=FFFFFF&amp;fc1=000000&amp;lc1=0000FF&amp;t=tecnologiad0c-21&amp;language=es_ES&amp;o=30&amp;p=8&amp;l=as4&amp;m=amazon&amp;f=ifr&amp;ref=as_ss_li_til&amp;asins=B076BRZ4VV&amp;linkId=a91907a564e2eee041fef2c7399653c8"></iframe>        <iframe style="width:120px;height:240px;" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//rcm-eu.amazon-adsystem.com/e/cm?lt1=_blank&amp;bc1=000000&amp;IS2=1&amp;bg1=FFFFFF&amp;fc1=000000&amp;lc1=0000FF&amp;t=tecnologiad0c-21&amp;language=es_ES&amp;o=30&amp;p=8&amp;l=as4&amp;m=amazon&amp;f=ifr&amp;ref=as_ss_li_til&amp;asins=B001ZAXBU0&amp;linkId=62f7b44fe031180e56be55e0448ee776"></iframe></p>
    <p>
        <strong>Pasos para conectar el termostato con el movil.</strong>
    </p>
    <p>
        Primero de todo tenemos que poner el termostado en modo busqueda de wifi.
    </p>
    <p>
        Con el termostato apagado, apretamos la fecha de abajo y el de encender
    </p>
    <p>
        <img src="/assets/images/Blog/CalefaccionDomotizada/Termostato1.jpg" border="0" class="img-fluid">

    </p>
    <p>
        Se pondrá la pantalla tal y como la veis en la foto anterior.
    </p>
    <p>
        Ahora hay que ir apretando el botón M, hasta que aparezca en pantalla FAC.
    </p>
    <p>
        <img src="/assets/images/Blog/CalefaccionDomotizada/Termostato2.jpg" border="0" class="img-fluid">

    </p>
    <p>
        Ahora vamos apretando la flecha de arriba, hasta poner el numero 32 en pantalla, y sino funciona con el 32, poner 10.
    </p>
    <p>
        <img src="/assets/images/Blog/CalefaccionDomotizada/Termostato3.jpg" border="0" class="img-fluid">

    </p>
    <p>
        Una vez puesto el número 32, le damos al botón de encender / parar.
    </p>
    <p>
        <img src="/assets/images/Blog/CalefaccionDomotizada/Termostato4.jpg" border="0" class="img-fluid">

    </p>
    <p>
        Ahora volvemos a apretar el de encender y flecha abajo, y ahora nos aparecerá el símbolo de wifi parpadeando.
    </p>
    <p>
        Vamos a la aplicación Beok
    </p>
    <P>
        Y vamos a ajustes
    </P>
    <p>
        <img src="/assets/images/Blog/CalefaccionDomotizada/App.jpg" border="0" class="img-fluid">

    </p>
    <p>
        Ahora insertamos el nombre de la wifi y la contraseña.
    </p>
    <p>
        <img src="/assets/images/Blog/CalefaccionDomotizada/App2.jpg" border="0" class="img-fluid">

    </p>
    <p>
        Ahora tras esperar unos segundos, ya podemos ir a búsqueda y aparecerá nuestro termostato wifi.
    </p>
    <p>
        <img src="/assets/images/Blog/CalefaccionDomotizada/App3.jpg" border="0" class="img-fluid">

    </p>
    <p>
        Animaros a probar, y ¡comentarme si lo habéis conseguido realizar correctamente!
    </p>
    <p>
        Nos vemos en próximos posts.
    </p>
    <p align="center"><iframe style="width:120px;height:240px;" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//rcm-eu.amazon-adsystem.com/e/cm?lt1=_blank&amp;bc1=000000&amp;IS2=1&amp;bg1=FFFFFF&amp;fc1=000000&amp;lc1=0000FF&amp;t=tecnologiad0c-21&amp;language=es_ES&amp;o=30&amp;p=8&amp;l=as4&amp;m=amazon&amp;f=ifr&amp;ref=as_ss_li_til&amp;asins=B076BRZ4VV&amp;linkId=a91907a564e2eee041fef2c7399653c8"></iframe>        <iframe style="width:120px;height:240px;" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//rcm-eu.amazon-adsystem.com/e/cm?lt1=_blank&amp;bc1=000000&amp;IS2=1&amp;bg1=FFFFFF&amp;fc1=000000&amp;lc1=0000FF&amp;t=tecnologiad0c-21&amp;language=es_ES&amp;o=30&amp;p=8&amp;l=as4&amp;m=amazon&amp;f=ifr&amp;ref=as_ss_li_til&amp;asins=B001ZAXBU0&amp;linkId=62f7b44fe031180e56be55e0448ee776"></iframe></p>
</div>