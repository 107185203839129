import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../../../services/auth.service';
import {ToastService} from '../../../services/toast.service';
import Swal from 'sweetalert2';
import {Usuario} from "../../../entities/usuarios";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  tittle = '¡Accede a tu cuenta de usuario!';
  text = 'Accede a tu cuenta de usuario mediante tu correo electronico y contraseña o crea una nueva cuenta, ¡es facilisimo!'
  usuario: Usuario;

  constructor(private authService: AuthService, private router: Router) {
    this.usuario = new Usuario();
  }

  ngOnInit() {
    if (this.authService.isAuthenticated()) {
      ToastService.info(`Hola ${this.authService.usuario.nombre.split(' ')[0]} ya estás autenticado!`);
      this.router.navigate(['/products']);
    }
  }

  login(): void {
    if (this.usuario.email == null) {
      ToastService.error('¡Correo electronico en blanco! Debes introducir uno');
      return;
    }
    if (this.usuario.password == null) {
      ToastService.error('¡Contraseña en blanco! Debes introducir una');
      return;
    }

    this.authService.login(this.usuario).subscribe(res => {

        this.authService.guardarUsuario(res.token);
        this.authService.guardarToken(res.token);
        const usuario = this.authService.usuario;
        this.router.navigate(['/products']);
        ToastService.succes(`Hola ${usuario.nombre.split(' ')[0]}, has iniciado sesión con éxito!`);
      }, err => {
      ToastService.info(JSON.stringify(err))
        switch (err.status) {
          case 400:
          case 401:
            ToastService.error(err.error.error);
            break;
          case 500:
            ToastService.error('Error en la connexión\nPor favor ponte en contacto con el administrador.')
            break;
        }
      }
    );
  }


  recovery() {
    let email;
    Swal.mixin({
      input: 'text',
      confirmButtonText: 'Siguiente &rarr;',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
      progressSteps: ['1']
    }).queue([
      {
        title: 'Introduce tu correo electrónico:'
      }
    ]).then((result) => {
      if (result.value) {
        email = result.value[0];
        Swal.fire({
          title: '¡Recuperando contraseña!',
          html: `Si existe el email introducido se le enviará un mensaje para poder recuperar la contraseña.`,
          confirmButtonText: 'Aceptar!',
          timer: 2000
        }).then((value => {
          if (value) {
            this.authService.recovery(email).subscribe((response: any) => {
              ToastService.info(response.msg);
            });
          }
        }));
      } else {
        ToastService.error("Has de introducir un correo")
      }
    });
  }
}
