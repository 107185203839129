<P>
</P>


<!--<h1 class="animated infinite shake">Animación shake</h1>
<h1 class="animated infinite tada">Animación tada</h1>
<h1 class="animated infinite rubberBand">Animación rubberBand</h1>-->


<div class="container " align="center">
    <h1 id="domotica" class="animate__animated animate__backInLeft">Domótica</h1>
</div>

<P></P>
<div class="container">
    <div class="row">
        <div class="col-4">
            <a class="nav-link" [routerLink]="'/enchufe'"><img src="/assets/images/Blog/EnchufeWifi.jpg" border="0" class="img-fluid card"></a>
            <a class="nav-link" [routerLink]="'/enchufe'">
                <h4 align="center">EnchufeWifi</h4>
            </a>
        </div>
        <div class="col-4">
            <a class="nav-link" [routerLink]="'/mandocero'">
                <img src="/assets/images/Blog/MandoUniversalWifi.jpg" border="0" class="img-fluid card">
            </a>
            <a class="nav-link" [routerLink]="'/mandocero'">
                <h4  align="center">Broadlink mando universal, como crear un mando desde cero</h4>
            </a>
        </div>
        <div class="col-4">
            <a class="nav-link" [routerLink]="'/beokgooglealexa'">
                <img src="/assets/images/Blog/BeokAltavozInteligente.jpg" border="0" class="img-fluid card">
            </a>
            <a class="nav-link" [routerLink]="'/beokgooglealexa'">
                <h4 align="center">Beok con Google Home / Alexa</h4>
            </a>
        </div>
    </div>
    <div class="row">
        <div class="col-4">
            <a class="nav-link" [routerLink]="'/mandouniversalwifi'">
                <img src="/assets/images/Blog/MandoUniversalWifi2.jpg" border="0" class="img-fluid card">
            </a>
            <a class="nav-link" [routerLink]="'/mandouniversalwifi'">
                <h4 align="center">Mando Universal Wifi</h4>
            </a>
        </div>
        <div class="col-4">
            <a class="nav-link" [routerLink]="'/tiraled'">
                <img src="/assets/images/Blog/leddetector.jpg" border="0" class="img-fluid card">
            </a>
            <a class="nav-link" [routerLink]="'/tiraled'">
                <h4 align="center">Tira led con detección de movimiento</h4>
            </a>
        </div>
        <div class="col-4">
            <a class="nav-link" [routerLink]="'/calefacciondomotizada'">
                <img src="/assets/images/Blog/BeokWifi.jpg" border="0" class="img-fluid card">
            </a>
            <a class="nav-link" [routerLink]="'/calefacciondomotizada'">
                <h4 align="center">Calefacción domotizada</h4>
            </a>
        </div>
    </div>
</div>