<P></P>
<div class="container" align="center">
    <h4>Packs de horas para servicios de asesoramiento, reparación y mantenimiento, pregúntanos por cualquier cosa que puedas necesitar.Servicio online o presencial.</h4>
    <br>
    <h4>Para servicios de análisis y programación trabajamos según presupuesto. Pregunta sin compromiso.</h4>

</div>
<P></P>
<div class="container-fluid">
    <div class="row">
        <div class="col-md-3 col-sm-3">
            <div class="card text-center" style="width: 18rem;">
                <div class="card-body">
                    <h5 class="card-title">Hora suelta</h5>
                    <p class="card-text">49€/hora + IVA</p>
                    <p class="card-text">Online: Consumo por fracciones de 15 min</p>
                    <p class="card-text">Presencial: Consumo hora entera</p>
                    <p class="card-text">Desplazamiento incluido en un radio de 20km</p>
                    <!--<a href="#" class="btn btn-primary">Comprar</a>-->
                    <a href="mailto:email@manuelmelero.com"><i class="fa fa-envelope-o fa-2x" aria-hidden="true"></i> </a> &nbsp;&nbsp;
                    <a href="tel:+34649627122"><i class="fa fa-phone fa-2x"></i> </a>
                </div>
            </div>
        </div>

        <div class="col-md-3 col-sm-3">
            <div class="card text-center" style="width: 18rem;">
                <div class="card-body">
                    <h5 class="card-title">Pack 5 horas</h5>
                    <p class="card-text">230€ + IVA</p>
                    <p class="card-text">Online: Consumo por fracciones de 15 min</p>
                    <p class="card-text">Presencial: Consumo hora entera</p>
                    <p class="card-text">Desplazamiento incluido en un radio de 20km</p>
                    <!--<a href="#" class="btn btn-primary">Comprar</a>-->
                    <a href="mailto:email@manuelmelero.com"><i class="fa fa-envelope-o fa-2x" aria-hidden="true"></i> </a> &nbsp;&nbsp;
                    <a href="tel:+34649627122"><i class="fa fa-phone fa-2x"></i> </a>
                </div>
            </div>
        </div>

        <div class="col-md-3 col-sm-3">
            <div class="card text-center" style="width: 18rem;">
                <div class="card-body">
                    <h5 class="card-title">Pack 10 horas</h5>
                    <p class="card-text">430€ + IVA</p>
                    <p class="card-text">Online: Consumo por fracciones de 15 min</p>
                    <p class="card-text">Presencial: Consumo hora entera</p>
                    <p class="card-text">Desplazamiento incluido en un radio de 20km</p>
                    <!--<a href="#" class="btn btn-primary">Comprar</a>-->
                    <a href="mailto:email@manuelmelero.com"><i class="fa fa-envelope-o fa-2x" aria-hidden="true"></i> </a> &nbsp;&nbsp;
                    <a href="tel:+34649627122"><i class="fa fa-phone fa-2x"></i> </a>
                </div>
            </div>
        </div>

        <div class="col-md-3 col-sm-3">
            <div class="card text-center" style="width: 18rem;">
                <div class="card-body">
                    <h5 class="card-title">Pack 20 horas</h5>
                    <p class="card-text">800€ + IVA</p>
                    <p class="card-text">Online: Consumo por fracciones de 15 min</p>
                    <p class="card-text">Presencial: Consumo hora entera</p>
                    <p class="card-text">Desplazamiento incluido en un radio de 20km</p>
                    <!--<a href="#" class="btn btn-primary">Comprar</a>-->

                    <a href="mailto:email@manuelmelero.com"><i class="fa fa-envelope-o fa-2x" aria-hidden="true"></i> </a> &nbsp;&nbsp;
                    <a href="tel:+34649627122"><i class="fa fa-phone fa-2x"></i> </a>

                </div>
            </div>
        </div>

    </div>

</div>
<div>&nbsp;</div>
<div>&nbsp;</div>
<div>&nbsp;</div>
<div class="container" align="center">
    Los packs caducan a los tres meses.
    <br>En el caso de desplazamiento superior a 20km tendrá un coste de 10€.
    <br>Las horas serán consumidas bajo cita previa.
</div>
<div>&nbsp;</div>
<div>&nbsp;</div>
<div>&nbsp;</div>