<div class="container mt-5" >
  <div class="row align-items-center">
    <div class="col-12">
      <h4 class="titulo">Restablecimiento de la contraseña de: {{usuario?.nombre}}</h4>
      <p class="lead">Introduce tu nueva contraseña para poder iniciar sesión en el sistema.</p>
    </div>
  </div>
  <hr>
  <div *ngIf="email;else noUser">
    <form class="form-newPassowrd" [formGroup]="form" (ngSubmit)="changePassword()">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="email"><i class="fa fa-envelope bigicon"></i></span>
        </div>
        <input formControlName="email"
               [(ngModel)]='email'
               readonly
               type="email" class="form-control"
               name="email" id="emailInput" autocomplete="username">
      </div>
      <div class="form-group">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fa fa-key bigicon"></i></span>
          </div>
          <input
                 formControlName="password"
                 id="password"
                 type="password"
                 class="form-control" autocomplete="new-password" placeholder="Contraseña">
          <div *ngIf="f.password.touched && f.password.invalid" class="alert alert-danger col-12">
            <div *ngIf="f.password.errors.required">Contraseña obligatoria.</div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fa fa-key bigicon"></i></span>
          </div>
          <input
            formControlName="confirmPassword"
            id="confirmPassword"
            type="password"
            class="form-control" autocomplete="new-password" placeholder="Repite la contraseña">
          <div *ngIf="f.confirmPassword.touched && f.confirmPassword.invalid" class="alert alert-danger col-12">
            <div *ngIf="f.confirmPassword.errors.required">Contraseña obligatoria.</div>
            <div *ngIf="f.confirmPassword.errors.confirmedValidator">Las contraseñas no coinciden.</div>
          </div>
        </div>
      </div>
      <hr>
      <button class="btn btn-success btn-block" type="submit" [disabled]="!form.valid">
        <fa-icon [icon]="faThumbsUp"></fa-icon>
        Cambiar contraseña
      </button>
    </form>
  </div>
  <ng-template #noUser>
    <div class="alert alert-danger text-center" role="alert">
      <p>¡El token es invalido o ha expirado, vuelve a solicitar una nueva contraseña!</p>
      <p><a class="text-center" (click)="recoverPassword()" id="forgot_pswd" style="cursor: pointer">Recuperar
        contraseña</a></p>
    </div>
  </ng-template>
</div>
