<P></P>
<div class="container" align="center">
    <h1 class="animate__animated animate__backInLeft">Sistemas</h1>
</div>
<P></P>
<div class="container">
    <div class="row">
        <div class="col-4">
            <a class="nav-link" [routerLink]="'/decalogoseguridad'">
                <img src="/assets/images/Blog/DecalogoCiberseguridad.jpg" border="0" class="img-fluid card">
            </a>
            <a class="nav-link" [routerLink]="'/decalogoseguridad'">
                <h4 align="center">Decálogo de Ciberseguridad</h4>
            </a>
        </div>
        <div class="col-4">

        </div>
        <div class="col-4">

        </div>
    </div>

</div>