import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {faAt, faKey, faThumbsUp} from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import {UsuarioService} from "../../../services/usuario.service";
import {AuthService} from "../../../services/auth.service";
import {ToastService} from "../../../services/toast.service";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss']
})
export class NewPasswordComponent implements OnInit {
  faKey = faKey;
  faAt = faAt;
  faThumbsUp = faThumbsUp;
  usuario: any;
  email: string;
  password: string;
  confirmPassword: string;
  token: string;
  JSON: any;
  form: FormGroup = new FormGroup({});

  constructor(
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private usuarioService: UsuarioService,
    private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute) {
    this.JSON = JSON;
    this.form = this.fb.group({
      email: ['', []],
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]]
    }, {
      validator: this.confirmedValidator('password', 'confirmPassword')
    });
  }

  confirmedValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({confirmedValidator: true});
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  get f() {
    return this.form.controls;
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.token = params['token'];
      this.usuarioService.checkTokenNewPassword(this.token).subscribe((data) => {
        this.usuario = data.usuario;
        this.email = this.usuario.email;
        this.cdr.detectChanges();
      });
    });
  }

  changePassword() {
    if (!this.form.valid) {
      ToastService.error('No hagas trampas, rellena correctamente el formulario');
      return;
    }
    this.password = this.form.controls.password.value;
    this.confirmPassword = this.form.controls.confirmPassword.value;
    if (this.password != this.confirmPassword) {
      ToastService.error("Las contraseñas deben de ser iguales")
      return;
    }
    this.usuarioService.setNewPassword(this.token, {
      password: this.password,
      confirmPassword: this.confirmPassword
    }).subscribe((data) => {
      this.router.navigate(['/login']).then(() => {
        ToastService.succes(data.msg);
      });
    });
  }

  async recoverPassword() {
    const {value: email} = await Swal.fire({
      title: 'Introduce tu email',
      input: 'email',
      inputPlaceholder: 'alortegama@gmail.com'
    });
    if (email) {
      this.authService.recovery(email).subscribe((data: any) => {
        if (data.status === 'OK') {
          this.router.navigate(['/home']).then(() => {
            ToastService.succes(data.msg);
          });
        }
      });
    }
  }
}
