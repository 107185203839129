import {Component, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {Usuario} from '../../entities/usuarios';
import {AuthService} from '../../services/auth.service';
import {UsuarioService} from '../../services/usuario.service';
import {ToastService} from '../../services/toast.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.css']
})
export class PerfilComponent implements OnInit {


  usuario: Usuario = new Usuario();
  tittle = '';
  text = 'Siempre que lo necesites, ¡actualiza tus datos personales!';


  constructor(private authService: AuthService, private usuarioService: UsuarioService, private router: Router) {

  }

  ngOnInit() {
    this.authService.getUsuario().subscribe(response => {
      this.usuario = response.usuario;
      this.tittle=`Tu perfil ${this.usuario.nombre.split(' ')[0]}`
    });
  }

  update(contactForm: NgForm) {
    if (contactForm.valid) {
      this.usuarioService.update(this.usuario).subscribe(() => {
        this.router.navigate(['/products']);
        ToastService.succes('Datos actualizados correctamente');
      });
    }
  }
}
