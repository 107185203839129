<P></P>
<div class="container" align="center">
    <h1>Tu ordenador va lento? Como recuperar la velocidad?</h1>
    <p>
        ¿Con el paso del tiempo vas notando que tu ordenador cada vez va más lento?
        <br>Ahora te comentare una serie de cosas que puedes hacer para hacer que tu ordenador funcione mejor.
        <br>Lo primero de todo lo que tienes que hacer es mirar los programas que se están ejecutando al inicio de Windows, aquellos programas que se ejecutan automáticamente al arrancar el ordenador.
        <br>Abre el administrador de tareas de Windows, haciendo clic con el botón derecho en la barra de Windows y haciendo clic en el administrador de tareas. Una vez abierto el administrador de tareas veras la pestaña inicio y veras una lista de programas
        que pone que están habilitados o deshabilitados en el inicio. Déjalos todos como deshabilitados exceptuando aquellos que quieras que se ejecuten al arrancar el pc, por ejemplo, el antivirus. Vas haciendo clic en cada uno de los programas y luego
        clic en deshabilitar.
    </p>
    <div class="row">
        <div class="col-6">
            <img src="/assets/images/Blog/PcLento/AdministradorTareas1.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-6">
            <img src="/assets/images/Blog/PcLento/AdministradorTareas-300x266.jpg" border="0" class="img-fluid">
        </div>
    </div>
    <p>
        - Ya que tenemos el administrador de tareas abierto, otra de las cosas que podemos hacer es mirar en la pestaña de procesos, cuales son aquellos procesos que nos están consumiendo más cpu o más memoria. Si son procesos no reconocidos podemos mirar por
        internet si son posibles virus.
    </p>
    <p>
        O bien si son programas que no utilizamos podemos proceder a su desinstalación.
        <br>Este punto va en relación con los dos siguientes, desinstalación de programas y revisión de virus.
    </p>
    <p>
        - Desinstala los programas que no utilices.
    </p>
    <p>
        Desde inicio, escribes agregar o quitar programas , y de la lista que aparece, desinstala todos aquellos programas que ya no utilices, que en su día instalaste pero que ahora ya no necesitas.
    </p>
    <div class="row">
        <div class="col-6">
            <img src="/assets/images/Blog/PcLento/agregarquitarprogramas.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-6">
            <img src="/assets/images/Blog/PcLento/agregarquitarprogramas1.jpg" border="0" class="img-fluid">
        </div>
    </div>
    <p>
        Virus y spam, revisa que no tengas ningún virus o spam. Instala algún antivirus gratuito y analiza tu pc. Te propongo instalar el avast la versión free y el superantispyware y haz una análisis del pc.
    </p>
    <div class="row">
        <div class="col-6">
            <img src="/assets/images/Blog/PcLento/superantispyware.png" border="0" class="img-fluid">
        </div>
        <div class="col-6">
            <img src="/assets/images/Blog/PcLento/avast.jpg" border="0" class="img-fluid">
        </div>
    </div>

    <p>
        Limpia el registro de windows.
        <br>Instala un programa para hacer limpieza de registro y de archivos temporales, te recomiendo instalar el ccleaner y hacer las dos opciones que te indico en la siguiente imagen. Limpieza de archivos y limpieza de registro.
    </p>
    <p>
        <img src="/assets/images/Blog/PcLento/ccleaner-300x77.jpg" border="0" class="img-fluid">
    </p>
    <p>
        <img src="/assets/images/Blog/PcLento/ccleaner2-300x211.jpg" border="0" class="img-fluid">
    </p>
    <p>
        Después de haber realizado esto, has tenido que notar que tu pc va bastante mejor. Aun así, te dejo unos tips adicionales para acabar de hacer limpieza.
    </p>
    <p>
        -Revisa que no tengas ningún conflicto en tus dispositivos, en la barra de Windows escribe administrador de dispositivos y en la pantalla que te aparece, comprueba que no tengas ningún signo de exclamación.
        <br>Si te aparece alguna exclamación tendrás que mirar de solucionarlo, tendrás que buscar los drivers en la página del fabricante para mirar de actualizarlos. O bien darle a la opción de Windows directamente, clic con el botón derecho encima
        del dispositivo que tiene el conflicto y darle a actualizar el controlador.
    </p>
    <p>
        <img src="/assets/images/Blog/PcLento/administradordispositivos-300x218.jpg" border="0" class="img-fluid">
    </p>

    <p>
        Busca que no tengas alguna actualización de Windows pendiente de hacer
    </p>
    <p>
        <img src="/assets/images/Blog/PcLento/actualizarwindows-300x251.jpg" border="0" class="img-fluid">
    </p>


    <p>
        Espero haber podido ser de utilidad, deja tus comentarios, con próximas publicaciones que podrían ser de tu interés.
    </p>

</div>