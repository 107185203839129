import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Usuario} from '../../entities/usuarios';
import {UsuarioService} from '../../services/usuario.service';
import {ToastService} from '../../services/toast.service';
import {Router} from "@angular/router";

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  tittle = "Registro";
  text = "";

  usuario: Usuario;
  submitted = true;


  form: FormGroup = new FormGroup({});
  controls = {}

  constructor(private fb: FormBuilder, private usuarioService: UsuarioService, private router: Router) {
    this.form = fb.group({
      acceptTerms: [false, Validators.requiredTrue],
      name: ['', [Validators.required, Validators.minLength(3)]],
      telefono: ['', [Validators.required, Validators.pattern('^(6|7|9)+([0-9]){8}$'), Validators.minLength(9)]],
      passwords: fb.group({
        password: ['', [Validators.required]],
        confirm_password: ['', [Validators.required]],
      }, {validator: this.confirmPassword()}),
      emails: fb.group({
        email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
        confirm_email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]]
      }, {validator: this.confirmMail()}),
      direccion: ['', [Validators.required]],
      provincia: ['', [Validators.required]],
      ciudad: ['', [Validators.required]],
      cp: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(5)]]

    });
    this.usuario = new Usuario();
  }


  confirmMail() {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls['email'];
      const matchingControl = formGroup.controls['confirm_email'];
      if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({check: true});
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  confirmPassword() {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls['password'];
      const matchingControl = formGroup.controls['confirm_password'];
      if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({check: true});
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  get f() {
    return this.form.controls;
  }

  ngOnInit() {
  }

  register() {
    if (this.form.valid) {
      this.usuario = {
        nombre: this.form.get('name').value,
        telf: this.form.get('telefono').value,
        password: this.form.get('passwords.password').value,
        email: this.form.get('emails.email').value,
        direccion: this.form.get('direccion').value,
        provincia: this.form.get('provincia').value,
        ciudad: this.form.get('ciudad').value,
        cp: this.form.get('cp').value,
      }
      console.log(this.usuario);

      this.usuarioService.create(this.usuario).subscribe(() => {
        this.router.navigate(['/login']);
        ToastService.succes('Usuario creado correctamente');
      });
    } else {
      if (!this.form.value.acceptTerms) {
        this.submitted = false;
      }
      ToastService.error("El formulario contiene errores")
    }
  }

  onCheckChange($event): void {
    this.submitted = $event.target.checked;

  }
}
