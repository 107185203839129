<P></P>
<div class="container" align="center">
    <h1>Decálogo de Ciberseguridad</h1>
    <p>
        <iframe loading="lazy" id="embed_video" class="pagelayer-video-iframe" width="100%" height="500" src="//youtube.com/embed/nMPhMha9wAI?&autoplay=0&mute=0&loop=0" frameborder="0"></iframe>
    </p>
</div>
<div class="container" align="left">
    <p>
        <strong>1.-</strong> No abras archivos adjuntos, a no ser que estés seguro que es de un contacto conocido
    </p>
    <p>
        <strong>2.-</strong> No abras archivos adjuntos, a no ser que estés seguro que es de un contacto conocido.
    </p>
    <p>
        <strong>3.-</strong> Asegúrate que estas navegando por la pagina que deseas, que no sea una simulación de otra pagina.
        <br>Que sean seguras https.
    </p>
    <p>
        <strong>4.-</strong> Instálate un buen antivirus Activa el firewall Haz análisis periódicos
    </p>
    <p>
        <strong>5.-</strong> Usa el sentido común No pongas tus datos personales, a no ser que sea una pagina de confianza
    </p>
    <p>
        <strong>6.-</strong> No te fíes de nadie en internet. No des tus claves a nadie. Tu banco no te va a pedir claves por email -Usa el sentido común -No pongas tus datos personales, a no ser que sea una pagina de confianza
    </p>
    <p>
        <strong>7.-</strong> Utiliza contraseñas seguras: números, letras, mayúsculas, signos Cámbialas asiduamente y mas si son las de sitios importantes, bancos, email, etc..
    </p>
    <p>
        <strong>8.-</strong> Mantén sistema operativo y aplicaciones actualizadas
    </p>
    <p>
        <strong>9.-</strong> Descarga todas las aplicaciones de sitios oficiales
    </p>
    <p>
        <strong>10.-</strong> Descarga todas las aplicaciones de sitios oficiales
    </p>
    <p align="center">
        Espero os haya sido de mucha utilidad.
    </p>
</div>