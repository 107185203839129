import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Usuario} from '../entities/usuarios';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  constructor(private http: HttpClient, private authService: AuthService) {
  }

  create(usuario: Usuario) {
    return this.http.post<string>(environment.backend + '/usuarios/', usuario);
  }

  recovery(password: string, username: string, hash: string) {
    const formData = new FormData();
    formData.append('password', password);
    formData.append('hash', hash);
    return this.http.post<string>(environment.backend + '/usuario/recovery/' + username, formData);
  }

  private agregarToken(httpHeaders: HttpHeaders) {
    const token = this.authService.token;
    if (token != null) {
      return httpHeaders.append('Authorization', token);
    }
    return httpHeaders;
  }

  update(usuario: Usuario) {
    const httpHeaders = new HttpHeaders(
      {
        'Content-Type': 'application/json'
      }
    );
    console.log(usuario.id);
    return this.http.put<string>(environment.backend + '/usuarios/' + usuario.id, usuario, {headers: this.agregarToken(httpHeaders)});
  }

  checkTokenNewPassword(token: string) {
    return this.http.get<any>(environment.backend + '/login/reset/' + token);
  }


  setNewPassword(token: string, passwords: { password: string; confirmPassword: string }) {
    return this.http.post<any>(environment.backend + '/login/reset/' + token, passwords);

  }
}
