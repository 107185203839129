<div>&nbsp;</div>
<div>
    <div id="carouselBanner" class="carousel slide bg-white" data-ride="carousel">
        <ol class="carousel-indicators">
            <li data-target="#carouselBanner" data-slide-to="0" class="active"></li>
            <li data-target="#carouselBanner" data-slide-to="1"></li>
            <li data-target="#carouselBanner" data-slide-to="2"></li>
        </ol>
        <div class="carousel-inner">
            <div class="carousel-item active">
                <img src="/assets/images/Banners/banner1.jpg" class="d-block w-100" alt="...">
                <div class="carousel-caption d-none d-md-block">
                    <h5>Promociones por packs de horas</h5>
                    <p>A más horas contratadas, mejor precio hora</p>
                </div>
            </div>
            <div class="carousel-item">
                <img src="/assets/images/Banners/banner2.jpg" class="d-block w-100" alt="...">
                <div class="carousel-caption d-none d-md-block">
                    <h5>Tú eliges</h5>
                    <p>No cal que cargues con el pc nos desplazamos donde estés o bien nos conectamos.</p>
                </div>
            </div>
            <div class="carousel-item">
                <img src="/assets/images/Banners/banner3.jpg" class="d-block w-100" alt="...">
                <div class="carousel-caption d-none d-md-block">
                    <h5></h5>
                    <p></p>
                </div>
            </div>
        </div>
        <a class="carousel-control-prev" href="#carouselBanner" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselBanner" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
        </a>
    </div>
</div>
<div>&nbsp;</div>
<div class="container-fluid">
    <div class="row">
        <div class="col-md-5 col-sm-5">
            <div class="card card-flip h-100">
                <div class="card-front text-white">
                    <div class="card-body">
                        <i class="fa fa-search fa-5x float-right"></i>
                        <h3 class="card-title">Hardware / Windows / Antivirus / ...</h3>
                        <p class="card-text">Con nuestro servicio, podemos reparar cualquier problema que tenga tu sistema operativo Windows, mejorar su rendimiento y asegurarnos de que esté protegido contra virus y malware.</p>
                    </div>
                </div>
                <div class="card-back bg-white">
                    <div class="card-body">
                        <h3 class="card-title">Tanto a particulares como empresas</h3>
                        <p class="card-text">No es necesario que muevas tu ordenador, nos desplazamos y reparamos en casa del cliente</p>
                        <!--<a href="#" class="btn btn-outline-secondary">Más información</a>-->
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-2 col-sm-2">
            &nbsp;
        </div>
        <div class="col-md-5 col-sm-5">
            <div class="card card-flip h-100">
                <div class="card-front text-white">
                    <div class="card-body">
                        <i class="fa fa-search fa-5x float-right"></i>
                        <h3 class="card-title">¿Necesitas una solución personalizada que optimice tus procesos y te permita trabajar de manera más eficiente?</h3>
                        <p class="card-text">Te buscamos lo que necesitas o lo creamos.</p>
                    </div>
                </div>
                <div class="card-back bg-white">
                    <div class="card-body">
                        <!--<h3 class="card-title">¿Necesitas una solución personalizada que optimice tus procesos y te permita trabajar de manera más eficiente?</h3>-->
                        <p class="card-text">No importa qué tipo de negocio tengas o qué necesidades específicas tengas, mis servicios de programación a medida pueden ayudarte a optimizar tus procesos y llevar tu empresa al siguiente nivel. </p>
                        <!--<a href="#" class="btn btn-outline-secondary">Más información</a>-->
                    </div>
                </div>
            </div>
        </div>

    </div>


    <div class="row">&nbsp;</div>

    <div class="row">
        <div class="col-md-5 col-sm-5">
            <div class="card card-flip h-100">
                <div class="card-front text-white">
                    <div class="card-body">
                        <i class="fa fa-search fa-5x float-right"></i>
                        <h3 class="card-title">Web</h3>
                        <p class="card-text">Creación de paginas web, servicio de hosting, ...</p>
                        <p class="card-text">¿Estás buscando crear una página web profesional y atractiva para tu negocio o proyecto personal? ¡Nosotros podemos ayudarte!</p>
                        
                    </div>
                </div>
                <div class="card-back bg-white">
                    <div class="card-body">
                        <h3 class="card-title">Una página de presentación, una tienda online, la carta de un bar,...</h3>
                        <p class="card-text">Ofrecemos un servicio de creación de páginas web a medida, adaptado a las necesidades de cada cliente. Ya sea que necesites una página web sencilla para presentar tu negocio o una tienda en línea completamente funcional, podemos ayudarte a crear la página web perfecta para ti.</p>
                        
                        <!--<a href="#" class="btn btn-outline-secondary">Más información</a>-->
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-2 col-sm-2">
            &nbsp;
        </div>
        <div class="col-md-5 col-sm-5">
            <div class="card card-flip h-100">
                <div class="card-front text-white">
                    <div class="card-body">
                        <i class="fa fa-search fa-5x float-right"></i>
                        <h3 class="card-title">Estamos a tu lado y en el de la tecnología</h3>
                        <p class="card-text">¿Estás buscando una asesoría informática confiable y experta? En nuestro servicio de asesoría informática,
                             ofrecemos soluciones integrales para tus necesidades tecnológicas empresariales. 
                             Como experto en informática con años de experiencia en la industria estoy disponible para ayudarte en todas las áreas de la tecnología empresarial.</p>
                    </div>
                </div>
                <div class="card-back bg-white">
                    <div class="card-body">
                        <!--<h3 class="card-title">Asesoría Informática</h3>-->
                        <p class="card-text">Si estás buscando soluciones informáticas personalizadas, podemos ayudarte a diseñar, implementar y mantener un sistema informático de última generación que se adapte a las necesidades específicas de tu empresa. Ya sea que necesites una nueva red informática, servidores, sistemas de seguridad o soluciones de software, podemos ayudarte a implementar la mejor solución para tu empresa.</p>
                        <!--<a href="#" class="btn btn-outline-secondary">Más información</a>-->
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div class="row">&nbsp;</div>

    <div class="row">
        <div class="col-md-5 col-sm-5">
            <div class="card card-flip h-100">
                <div class="card-front text-white">
                    <div class="card-body">
                        <i class="fa fa-search fa-5x float-right"></i>
                        <h3 class="card-title">Control Horario</h3>
                        <p class="card-text">No esperes más para mejorar el control horario de tu empresa. Contáctanos hoy para obtener más información sobre nuestro software de control horario y cómo podemos ayudarte a llevar tu empresa al siguiente nivel.</p>
                    </div>
                </div>
                <div class="card-back bg-white">
                    <div class="card-body">
                        <h3 class="card-title"><img src="/assets/images/fichaje.jpg" width="50"></h3>
                        <p class="card-text">Aplicación web, para llevar el control horario, de una manera fácil y ágil</p>
                        <!--<a href="#" class="btn btn-outline-secondary">Solicita una demo</a>-->
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-2 col-sm-2">
            &nbsp;
        </div>
        <div class="col-md-5 col-sm-5">
            <div class="card card-flip h-100">
                <div class="card-front text-white">
                    <div class="card-body">
                        <i class="fa fa-search fa-5x float-right"></i>
                        <h3 class="card-title">Power BI!</h3>
                        <p class="card-text">Estamos comprometidos a ayudarte a aprovechar al máximo tus datos y tomar decisiones informadas. Con nuestra experiencia en Power BI, podemos ayudarte a conectar tus fuentes de datos, crear visualizaciones personalizadas y compartir informes con tu equipo.</p>
                    </div>
                </div>
                <div class="card-back bg-white">
                    <div class="card-body">
                        <h3 class="card-title">Podemos ayudarte a implementar Power BI </h3>
                        <p class="card-text">Si estás buscando soluciones de análisis de datos, podemos ayudarte a descubrir los patrones ocultos en tus datos y proporcionarte información valiosa que te ayudará a tomar decisiones informadas. Ya sea que necesites un análisis detallado para una presentación importante o una visión general de tus datos empresariales, podemos ayudarte a crear informes personalizados y visualizaciones interactivas para que puedas entender mejor tus datos.</p>
                        <!--<a href="#" class="btn btn-outline-secondary">Más información</a>-->
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>

<div>&nbsp;</div>
<!--<div class="container-fluid" style="width: 90%">
    <h3><img src="/assets/images/fichaje.jpg" width="50"> Programa para llevar el control horario.</h3>

</div>-->
<div>&nbsp;</div>
<div>&nbsp;</div>
<div>&nbsp;</div>