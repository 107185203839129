import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AuthService} from './auth.service';
import {ToastService} from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private authService: AuthService,
              private router: Router) {
  }
  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(e => {
        if (e.status === 401) {
          if (this.authService.isAuthenticated()) {
            this.authService.logout();
          }
          this.router.navigate(['/login']);
        }
        if (e.status===400){
          ToastService.warning(`${e.error.Error}`);
        }

        if (e.status === 403) {
          ToastService.warning(`Hola ${this.authService.usuario.nombre.split(' ')[0]} no tienes acceso a este recurso!`);
          this.router.navigate(['/products']);
        }
        return throwError(e);
      })
    );
  }
}
