<div id="logreg-forms" class="mt-5">
    <a routerLink="/register">
        <p class="text-center text-muted">¿No tienes cuenta? ¡Registrate justo aquí!</p>
        <button class="btn btn-secondary btn-block" type="button" id="btn-signup">
      <!--<fa-icon [icon]="faUserPlus"></fa-icon>-->
      Registrate
    </button>
    </a>
    <hr>
    <form>
        <div class="input-group mb-3">
            <div class="input-group-prepend">
                <!--<span class="input-group-text" id="email"><fa-icon [icon]="faAt"></fa-icon></span>-->
            </div>
            <input [(ngModel)]="usuario.email" type="email" class="form-control" name="email" id="emailInput" autocomplete="username" placeholder="Correo electrónico" required>
        </div>
        <div class="input-group mb-3">
            <div class="input-group-prepend">
                <!-- <span class="input-group-text" id="password"><fa-icon [icon]="faKey"></fa-icon></span>-->
            </div>
            <input [(ngModel)]="usuario.password" type="password" class="form-control" name="password" id="passwordInput" autocomplete="current-password" placeholder="Contraseña" required>
        </div>

        <hr>
        <button class="btn btn-info btn-block" (click)='login()' type="submit">
      <!--<fa-icon [icon]="faSignInAlt"></fa-icon>-->
      Inicia sesión
    </button>
        <a style="cursor: pointer" class="text-center text-danger" (click)="recovery()" id="forgot_pswd">¿Has olvidado tu contraseña?</a>
        <hr>
        <!-- <p>Don't have an account!</p>  -->

    </form>
    <br>

</div>