import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {AuthService} from "../services/auth.service";
import {ToastService} from "../services/toast.service";

@Injectable({
  providedIn: 'root'
})
export class NonAuthGuard implements CanActivate {
  constructor(public auth: AuthService, public router: Router) {
  }

  canActivate(): boolean {
    let authenticated:Boolean = this.auth.isAuthenticated();
    if (!authenticated){
      return true;
    }
    else{
      ToastService.error('Ya estas registrado en el sistema');
      return false;
    }
  }
}
