<hr>
<div class="container-small">

    <form #contactForm="ngForm" (ngSubmit)="update(contactForm)" id="contact-form" method="post" role="form" class="animated fadeIn fast">
        <div class="messages"></div>
        <div class="controls">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <span class="col-1 col-md-offset-2 text-center"><i class="fa fa-user bigicon"></i></span>
                        <label for="form_name">Nombre Completo</label>
                        <input id="form_name" type="text" name="name" class="form-control" placeholder="Nombre completo" readonly [(ngModel)]="usuario.nombre" required="required" data-error="Firstname is required.">
                        <div class="help-block with-errors"></div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <span class="col-1 col-md-offset-2 text-center"><i class="fa fa-phone bigicon"></i></span>
                        <label for="form_telf">Teléfono</label>
                        <input id="form_telf" type="tel" name="apellido" class="form-control" placeholder="+34 123 45 67 89" [(ngModel)]="usuario.telf" required="required" data-error="Teléfono requerido.">
                        <div class="help-block with-errors"></div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <span class="col-1 col-md-offset-2 text-center"><i class="fa fa-envelope-o bigicon"></i></span>
                        <label for="form_email">E-mail</label>
                        <input id="form_email" type="email" name="email" class="form-control" placeholder="Dirección e-mail" [(ngModel)]="usuario.email" required="required" data-error="Valid email is required.">
                        <div class="help-block with-errors"></div>
                    </div>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <span class="col-1 col-md-offset-2 text-center"><i class="fa fa-map bigicon"></i></span>
                        <label for="form_direccion">Dirección</label>
                        <input id="form_direccion" type="text" name="direccion" class="form-control" placeholder="C/ Falsa 123" [(ngModel)]="usuario.direccion" required="required" data-error="Dirección requerida.">
                        <div class="help-block with-errors"></div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <span class="col-1 col-md-offset-2 text-center"><i class="fa fa-map bigicon"></i></span>
                        <label for="form_ciudad">Ciudad</label>
                        <input id="form_ciudad" type="text" name="ciudad" class="form-control" placeholder="Madrid" [(ngModel)]="usuario.ciudad" required="required" data-error="Dirección requerida.">
                        <div class="help-block with-errors"></div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <span class="col-1 col-md-offset-2 text-center"><i class="fa fa-map bigicon"></i></span>
                        <label for="form_estado">Estado/Provincia/Región</label>
                        <input id="form_estado" type="text" name="estado" class="form-control" placeholder="Madrid" [(ngModel)]="usuario.provincia" required="required" data-error="Dirección requerida.">
                        <div class="help-block with-errors"></div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <span class="col-1 col-md-offset-2 text-center"><i class="fa fa-map bigicon"></i></span>
                        <label for="form_postal">Código Postal</label>
                        <input id="form_postal" type="number" name="cp" class="form-control" placeholder="28001" maxlength="5" size="5" [(ngModel)]="usuario.cp" required="required" data-error="Código postal requerido.">
                        <div class="help-block with-errors"></div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 text-right">
                    <input type="submit" class="btn btn-info" value="Actualizar Datos">
                </div>
            </div>
        </div>
    </form>
</div>