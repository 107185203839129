import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../../services/auth.service';
import {Router} from '@angular/router';
import {ToastService} from '../../../services/toast.service';
import {MatDialog} from '@angular/material';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  items = 0;
  open = false;
  

  constructor(public authService: AuthService,
              private router: Router,              
              public dialog: MatDialog) {
  }

  logout(): void {
    const name = this.authService.usuario.nombre.split(' ')[0];
    this.authService.logout();
    ToastService.succes(`${name}, has cerrado sesión con éxito!`);
    this.router.navigate(['/login']);
  }


  ngOnInit() {

  }

  showCart() {
    if (!this.authService.isAuthenticated()) {
      ToastService.warning('Porfavor inicia sesión o registrate para continuar');
      return;
    }
    if (this.items === 0) {
      ToastService.info('Has de añadir articulos a tu carrito!!');
      return;
    }
    if (!this.open) {
      this.open = true;
      
    }
  }

  perfil() {
    this.router.navigateByUrl('/perfil');

  }
}
