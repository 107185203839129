import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-envio',
  templateUrl: './envio.component.html',
  styleUrls: ['./envio.component.css']
})
export class EnvioComponent implements OnInit {

  tittle="Instrucciones";
  text="¿Cómo comprar en nuestra web?";
  constructor() { }

  ngOnInit() {
  }

}
