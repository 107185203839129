<P></P>
<div class="container" align="center">
    <h1>Amazon photos / almacenamiento ilimitado</h1>
    <p>
        Hasta hace poco Google fotos podía ser una excelente opción gratuita para el almacenamiento en la nube de nuestras fotos, ya que teníamos almacenamiento ilimitado.
        <br>Actualmente Google fotos ha dejado de dar la opción de almacenamiento ilimitado, y probando alternativas gratuitas he optado por Amazon photos ya que por el mismo precio de prime te permite almacenamiento ilimitado de fotos.
        <br>Vamos a ver cómo hacer la suscripción y subida de fotos a Amazon photos y ya que pagamos el prime aprovechar más sus posibilidades.
        <br>Es necesario ser de Amazon prime para disponer de almacenamiento ilimitado de fotos, pero ojo solo fotos, en videos solo permite 5 Gb.
        <br>Si no sois prime, a continuación, os dejo en enlace para haceros prime y poder aprovechar todas sus ventajas.
    </p>
    <p align="center">
        <iframe loading="lazy" src="https://rcm-eu.amazon-adsystem.com/e/cm?o=30&amp;p=48&amp;l=ur1&amp;category=premium&amp;banner=1E7ZEBFW3E0G3W1WXZ82&amp;f=ifr&amp;linkID=130726248de66357e0db394720a0045c&amp;t=tecnologiad0c-21&amp;tracking_id=tecnologiad0c-21"
            width="728" height="90" scrolling="no" border="0" marginwidth="0" frameborder="0" style="background-color: transparent; font-size: 12px; border-width: initial; border-style: none;"></iframe>
    </p>
    <p>
        Una vez tenemos la suscripción a prime, ya podemos acceder a Amazon photos.
    </p>
    <p>
        <a class="pagelayer-ele-link" href="https://amzn.to/3nteMy5" pagelayer-image-link-type="custom_url">
            <img src="/assets/images/Blog/amazonfotos/BanneAmazonFotos.jpg" title="BanneAmazonFotos" alt="amazon photos">

        </a>
    </p>
    <p>
        Nada más darle a comenzar, si no estamos logados en Amazon, nos pedirá hacerlo y nos saldrá una serie de avisos con información de Amazon photos.
    </p>
    <div class="row">
        <div class="col-3">
            <img src="/assets/images/Blog/amazonfotos/1.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/amazonfotos/2.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/amazonfotos/3.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/amazonfotos/4.jpg" border="0" class="img-fluid">
        </div>
    </div>
    <p>
        Una vez pasadas las cuatro pantallas podemos proceder a bajar el programa, para empezar la sincronización de fotos.
        <br>Vamos a empezar bajando la app para el pc.
    </p>
    <p>
        <img src="/assets/images/Blog/amazonfotos/TusAplicaciones2.jpg" border="0" class="img-fluid">
    </p>

    <p>
        Una vez descargada la aplicación, procedemos a su instalación.
    </p>
    <div class="row">
        <div class="col-3">
            <img src="/assets/images/Blog/amazonfotos/instalarAppPc2.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/amazonfotos/instalarAppPc3.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/amazonfotos/instalarAppPc4.jpg" border="0" class="img-fluid">
        </div>
    </div>
    <div class="row">
        <div class="col-3">
            <img src="/assets/images/Blog/amazonfotos/instalarAppPc5.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/amazonfotos/instalarAppPc6.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/amazonfotos/instalarAppPc7.jpg" border="0" class="img-fluid">
        </div>
    </div>
    <p>
        Una vez instalado introducimos usuario y contraseña de Amazon prime, y podemos proceder a su configuración.
    </p>
    <div class="row">
        <div class="col-3">
            <img src="/assets/images/Blog/amazonfotos/instalarAppPc8.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/amazonfotos/instalarAppPc9.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/amazonfotos/instalarAppPc10.jpg" border="0" class="img-fluid">
        </div>
    </div>
    <div class="row">
        <div class="col-3">
            <img src="/assets/images/Blog/amazonfotos/instalarAppPc11.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/amazonfotos/instalarAppPc12.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">

        </div>
    </div>

    <p>
        Ya tenemos instalada la aplicación en el pc y por defecto las fotos que estén en la carpeta de imágenes del pc se sincronizaran con Amazon photos.
    </p>
    <p>
        Si además vamos a copia de seguridad podemos configurar otras carpetas a sincronizar.
    </p>
    <div class="row">
        <div class="col-3">
            <img src="/assets/images/Blog/amazonfotos/instalarAppPc12-1.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/amazonfotos/instalarAppPc13-1.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/amazonfotos/instalarAppPc14.jpg" border="0" class="img-fluid">
        </div>
    </div>
    <p>
        También podemos instalar la aplicación en el móvil, vamos a Google play o app store y bajamos Amazon photos.
    </p>

    <div class="row">
        <div class="col-4">
            <img src="/assets/images/Blog/amazonfotos/AppAmazonPhotos1.jpeg" border="0" class="img-fluid">
        </div>
        <div class="col-4">
            <img src="/assets/images/Blog/amazonfotos/AppAmazonPhotos2.jpeg" border="0" class="img-fluid">
        </div>
        <div class="col-4">
            <img src="/assets/images/Blog/amazonfotos/AppAmazonPhotos3.jpeg" border="0" class="img-fluid">
        </div>
    </div>
    <div class="row">
        <div class="col-4">
            <img src="/assets/images/Blog/amazonfotos/AppAmazonPhotos4.jpeg" border="0" class="img-fluid">
        </div>
        <div class="col-4">
            <img src="/assets/images/Blog/amazonfotos/AppAmazonPhotos5.jpeg" border="0" class="img-fluid">
        </div>
        <div class="col-4">

        </div>
    </div>
    <p>
        Ahora también tendremos todas nuestras fotos del móvil sincronizadas con Amazon photos.
    </p>

</div>