<P></P>
<div class="container" align="center">
    <h1>Error: espacio insuficiente</h1>
    <p>
        Te sale el molesto mensaje de espacio insuficiente en tu teléfono o deseas optimizar tu espacio. Te voy a dar unos cuantos tips, para liberar espacio. La mayoría de los tips son válidos para iphone y para android. - Aunque obvio no esta de mas mencionarlo.
        Elimina todas aquellas <strong>aplicaciones que no utilizas</strong>. Iphone incluso tiene una opción que al marcar "desinstalar apps no utilizadas" lo hace automáticamente.
    </p>
    <p>
        - Instálate un programa para subir las fotos a la nube. El mas utilizado <strong>Google Fotos</strong>. Autoriza al programa a subir las fotos. Elige la cuenta para realizar la copia y activa la copia de seguridad.
    </p>

    <div class="row">
        <div class="col-6">
            <img src="/assets/images/Blog/espacioInsuficiente/googlefotos1-135x300.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-6">
            <img src="/assets/images/Blog/espacioInsuficiente/googlefotos2-191x300.jpg" border="0" class="img-fluid">
        </div>
    </div>
    <p>
        Una vez hecha la copia de seguridad, antes de seguir con el siguiente paso, nos aseguramos que la copia de seguridad esta realizada. Entra a Google fotos desde un ordenador y comprueba que estén las fotos. Y luego ya procedes a borrar las fotos del teléfono.
        Le das clic a liberar, borraras las fotos de tu teléfono y las tendrás en la nube.
    </p>
    <p>
        -<strong> Files de Google (solo Android)</strong> Instala files de Google te permitirá borrar archivos de una manera rápida como archivos temporales, memes, capturas de pantalla antiguas, archivos descargados,…
    </p>

    <div class="row">
        <div class="col-3">
            <img src="/assets/images/Blog/espacioInsuficiente/GoogleFiles1.jpeg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/espacioInsuficiente/GoogleFiles2.jpeg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/espacioInsuficiente/GoogleFiles3.jpeg" border="0" class="img-fluid">
        </div>
    </div>
    <p>
        Ahora ya puedes utilizar las diferentes opciones de limpieza del Google Files.
    </p>
    <p>
        <img src="/assets/images/Blog/espacioInsuficiente/GoogleFiles5-135x300.jpeg" border="0" class="img-fluid">
    </p>
    <p>
        - <strong> Eliminar contenido carpeta sent de whatsapp</strong> Puedes utilizar el mismo Files, u otro gestor de archivos. La idea es eliminar el contenido de la carpeta sent. Todo lo que enviamos por whatsapp se queda guardada una copia en esta
        carpeta. Pero se supone que si lo enviamos nosotros es que los archivos ya los tenemos, así que todo lo que este en esta carpeta lo podemos eliminar. Vamos a explorar , almacenamiento interno, whastapp, Media, Whatsapp Images , sent, apretamos
        un rato en una de las imágenes para poder seleccionar todo y procedemos a borrar. Podemos hacer lo mismo, con la carpeta sent de Whatsapp Video. Esta es una de las maneras con las que suelo liberar mas espacio.
    </p>

    <div class="row">
        <div class="col-3">
            <img src="/assets/images/Blog/espacioInsuficiente/FilesWhatsapp1.jpeg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/espacioInsuficiente/FilesWhatsapp2.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/espacioInsuficiente/FilesWhatsapp3.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/espacioInsuficiente/FilesWhatsapp4.jpg" border="0" class="img-fluid">
        </div>
    </div>
    <div class="row">
        <div class="col-3">
            <img src="/assets/images/Blog/espacioInsuficiente/FilesWhatsapp5.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/espacioInsuficiente/FilesWhatsapp6.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/espacioInsuficiente/FilesWhatsapp7.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/espacioInsuficiente/FilesWhatsapp8.jpg" border="0" class="img-fluid">
        </div>
    </div>
    <p>
        - <strong>Eliminar cache de aplicaciones</strong> Esto lo que hace es eliminar todo lo que almacenan las aplicaciones para funcionar, como preferencias, valores de usuario, archivos descargados, … Así que antes de realizar este paso asegúrate
        que no te hará falta mas adelante. En el caso de Android, vas a ajustes, aplicaciones , seleccionas la aplicación , almacenamiento y aquí si que tienes la opción de eliminar la cache.
    </p>
    <div class="row">
        <div class="col-2">
            <img src="/assets/images/Blog/espacioInsuficiente/Cache1.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-2">
            <img src="/assets/images/Blog/espacioInsuficiente/Cache2.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-2">
            <img src="/assets/images/Blog/espacioInsuficiente/Cache3.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-2">
            <img src="/assets/images/Blog/espacioInsuficiente/Cache4.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-2">
            <img src="/assets/images/Blog/espacioInsuficiente/Cache5.jpg" border="0" class="img-fluid">
        </div>
    </div>
    <p>
        En iphone, la opción es desinstalar e instalar la aplicación de nuevo. Excepto con safari , que si vas a configuración , safari , si que tienes la opción de bajando para debajo de eliminar historial y datos del sitio web.
    </p>
    <div class="row">
        <div class="col-3">
            <img src="/assets/images/Blog/espacioInsuficiente/CacheIphone1.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/espacioInsuficiente/CacheIphone2.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/espacioInsuficiente/CacheIphone3.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/espacioInsuficiente/CacheIphone4.jpg" border="0" class="img-fluid">
        </div>
    </div>

    <p>
        <strong>
        -ICloud 
       </strong>
    </p>
    <p>
        En iphone tienes la opción de subir las fotos al icloud y marcar la opción optimizar almacenamiento. El iphone automáticamente baja la calidad de las fotos almacenadas en el iphone. Siempre que las desees con alta calidad puedes volver a acceder al icloud
        y bajarlas.

    </p>
    <p>
        <img src="/assets/images/Blog/espacioInsuficiente/IphoneFotos-169x300.jpg" border="0" class="img-fluid">

    </p>
    <p>
        Espero os haya sido de mucha utilidad.
    </p>

</div>