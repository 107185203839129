<P></P>
<div class="container" align="center">
    <h1>Mando universal Wifi, IR, RF</h1>
    <p>
        <a href="https://amzn.to/3fOJvTP"> <img src="/assets/images/Blog/MandoUniversalWifi/MandoUniversal.jpg" border="0" class="img-fluid"></a>
    </p>
    <p>
        Una excelente solución para domotizar en tu casa todo aquello que tenga un mando a distancia. Por ejemplo, puedes configurar el mando universal con el mando de tu aire acondicionado. Con la aplicación broadlink podrás desde cualquier lugar enchufar, parar,
        subir o bajar temperatura, … del aire acondicionado de tu casa. Tiene múltiples utilidades, tiene muchos mandos preaprendidos y una opción que puedes crear tú los mandos que no existan y enseñarle a la aplicación cuales son los botones a insertar
        en el mando universal.
    </p>
    <p>
        Poco a poco entre este post y posteriores intentare ir explicando un poco como funciona esta magnífica solución, fácil de utilizar y económica.
    </p>
    <p>
        Además, es compatible con google home, desde que lo tengo instalado en mi casa me olivado de buscar los mandos de los diferentes aparatos. En una sola aplicación del móvil tengo todos los mandos que necesito. Yo lo tengo instalado en el comedor y tengo
        puesto el mando de la tele, el de la luces del comedor y del aire acondicionado, con lo que con una aplicación del móvil gestiono toda la estancia.
    </p>
    <p>
        Os voy a hacer una breve introducción de como instalarlo y en próximos post ire entrando en detalles de como conectarlo a Google home o como crear mandos que no existan. Lo primero que tenemos que hacer es bajar a nuestro smartphone la aplicación broadlink.
        Os dejo los links tanto de apple como de android.
    </p>
    <div class="row">
        <div cols="6">
            <a href="https://apps.apple.com/es/app/broadlink/id1450257910"> <img src="/assets/images/Blog/MandoUniversalWifi/appstore.jpg" border="0" class="img-fluid"></a>
        </div>
        <div cols="6">
            <a href="https://play.google.com/store/apps/details?id=cn.com.broadlink.econtrol.international&hl=es_419"> <img src="/assets/images/Blog/MandoUniversalWifi/PlayStore.jpg" border="0" class="img-fluid"></a>
        </div>
    </div>
    <P>
        Una vez bajada la aplicación podemos proceder a enchufar nuestro broadlink a la corriente eléctrica y veremos que el broadlink se queda con la luz roja parpadeando esperando que nos conectemos desde la aplicación para empezar a configurar.
    </P>
    <P>
        En la parte derecha de la aplicación tenemos el botón de más.
    </P>
    <P>
        Y aquí tendremos la opción de añadir dispositivo. Nos aparecerá una pequeña guía y ya hará el rastreo para encontrar el dispositivo.
    </P>
    <div class="row">
        <div class="col-4">
            <img src="/assets/images/Blog/MandoUniversalWifi/1.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-4">
            <img src="/assets/images/Blog/MandoUniversalWifi/2.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-4">
            <img src="/assets/images/Blog/MandoUniversalWifi/3.jpg" border="0" class="img-fluid">
        </div>
    </div>
    <P>
        Una vez añadido ya podemos proceder a incluir el mando que deseamos.
        <br>Os voy a explicar como introducir por ejemplo un mando de aire acondicionado.
        <br>En la parte derecha tal y como hemos hecho antes vamos al boton mas, pero ahora vamos a agregar remoto.
        <br>Nos preguntara que tipo de mando queremos crear, le diremos dispositivo IR y RF.
        <br>Nos preguntara en que controlador lo queremos crear, elegiremos el que hemos creado anteriormente.
        <br>A continuación nos aparecera una lista de tipos de dispositivos que podemos configurar. Seleccionaremos el aire acondicionado. A continuación nos aparecera una lista de marcas elegiremos la nuestra y ya tendremos nuestro aire acondicionado
        configurado.
    </P>
    <div class="row">
        <div class="col-2">
            <img src="/assets/images/Blog/MandoUniversalWifi/4.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-2">
            <img src="/assets/images/Blog/MandoUniversalWifi/5.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-2">
            <img src="/assets/images/Blog/MandoUniversalWifi/6.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-2">
            <img src="/assets/images/Blog/MandoUniversalWifi/8.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-2">
            <img src="/assets/images/Blog/MandoUniversalWifi/9.jpg" border="0" class="img-fluid">
        </div>
    </div>
    <P>
        En próximos posts miraremos la opción de enseñar a broadlink aquellos mandos que no tiene preaprendidos, como podemos crear un mando de cero y ir guardando todos los botones del mando.
    </P>
    <P>
        A continuación os dejo el enlace desde el que podéis adquirir el Mando Universal Broadlink en Amazon
    </P>
    <P>
        <a href="https://amzn.to/3fOJvTP"> <img src="/assets/images/Blog/MandoUniversalWifi/MandoUniversal.jpg" border="0" class="img-fluid"></a>
    </P>
</div>