<nav class="navbar sticky-top navbar-expand-lg navbar-dark" style="background-color: #004d9e;">
    <div class="container-fluid">
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

        <!--<div class="mr-2 ml-2 d-block d-lg-none" style="color: white;white-space:nowrap" (click)="showCart()" data-toggle="collapse" data-target=".navbar-collapse.show">
            <button class="btn btn-info"><i
        class="fa fa-shopping-cart fa-lg red-cart"></i> Carrito: {{items}} </button>

        </div>-->
        <div class="collapse navbar-collapse" id="navbarNav">

            <ul class="navbar-nav mr-auto">
                <li class="nav-item" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">
                    <a class="nav-link" [routerLink]="'/home'">Home</a>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Blog
                  </a>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <a class="dropdown-item" [routerLink]="'/domotica'">Domótica</a>
                        <a class="dropdown-item" [routerLink]="'/sistemas'">Sistemas</a>
                        <a class="dropdown-item" [routerLink]="'/informaticainicial'">Informática inicial</a>
                        <!--<div class="dropdown-divider"></div>-->
                    </div>
                </li>


                <li class="nav-item" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">
                    <a class="nav-link" [routerLink]="'/servicios'">Packs Horas</a>
                </li>
                <li class="nav-item" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">
                    <a class="nav-link" [routerLink]="'/contact'">Contacto</a>
                </li>
                <!--<li *ngIf="!authService.isAuthenticated()" class="nav-item d-block d-lg-none" data-toggle="collapse" data-target=".navbar-collapse.show">
                    <div class="row">
                        <div class="col-6 p-1">
                            <button [routerLink]="'/login'" class="btn btn-outline-primary btn-block" type="submit">Iniciar sesión
              </button>
                        </div>
                        <div class="col-6 p-1">
                            <button [routerLink]="'/register'" class="btn btn-outline-warning btn-block" type="submit">Registrate
              </button>
                        </div>
                    </div>
                </li>
                <li *ngIf="authService.isAuthenticated()" class="nav-link d-block d-lg-none">
                    <div class="row">
                        <div class="col-6 p-1">
                            <button (click)="perfil()" class="btn btn-outline-primary btn-block" type="submit" data-toggle="collapse" data-target=".navbar-collapse.show">Mi Perfil
              </button>
                        </div>
                        <div class="col-6 p-1">
                            <button (click)="logout()" class="btn btn-outline-warning btn-block" type="submit" data-toggle="collapse" data-target=".navbar-collapse.show">Cerrar sesión
              </button>
                        </div>
                    </div>
                </li>-->
            </ul>
        </div>
        <!--
        <ul class="navbar-nav d-none d-lg-block" *ngIf="authService.isAuthenticated()">
            <li class="dropdown dropleft">
                <a class="btn btn-outline-primary dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {{this.authService.usuario.nombre|split:' ':0}}
        </a>

                <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <button (click)="perfil()" class="dropdown-item" type="submit" data-toggle="collapse" data-target=".navbar-collapse.show">Perfil
          </button>
                    <button (click)="logout()" class="dropdown-item" type="submit" data-toggle="collapse" data-target=".navbar-collapse.show">Cerrar sesión
          </button>
                </div>
            </li>

        </ul>

        
        <a [routerLink]="['/login']" class="btn btn-outline-primary mr-2 d-none d-lg-block" *ngIf="!authService.isAuthenticated()">Iniciar sesión</a>
        <a [routerLink]="['/register']" class="btn btn-outline-warning mr-2 d-none d-lg-block" *ngIf="!authService.isAuthenticated()">Registrate</a>
        <div class="mr-2 ml-2 d-none d-lg-block " style="color: white;white-space:nowrap" (click)="showCart()" data-toggle="collapse" data-target=".navbar-collapse.show">
            <button class="btn btn-info"><i
        class="fa fa-shopping-cart fa-lg red-cart"></i> Carrito: {{items}}
      </button>
        </div>
        -->
    </div>
</nav>

<!--
<nav class="navbar sticky-top navbar-expand-lg navbar-dark bg-dark">
  <div class="col-12">
    <div class="row justify-content-around">
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">
            <a class="nav-link" [routerLink]="'/products'">Productos</a>
          </li>
          <li class="nav-item" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">
            <a class="nav-link" [routerLink]="'/contact'">Contacto</a>
          </li>
          <li class="nav-item" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">
            <a class="nav-link" [routerLink]="'/envio'">Intrucciones</a>
          </li>
          <li *ngIf="!authService.isAuthenticated()" class="nav-item d-block d-lg-none" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">
            <a class="nav-link" [routerLink]="'/register'">Registrate</a>
          </li>

        </ul>
      </div>
      <ul class="navbar-nav">
        <li *ngIf="!authService.isAuthenticated();else auth" data-toggle="collapse" data-target=".navbar-collapse.show">
          <a [routerLink]="['/login']" class="btn btn-outline-primary mr-2">Iniciar sesión</a>
        </li>
        <ng-template #auth>
          <li class="dropdown dropleft">
            <a class="btn btn-outline-primary dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
               data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              {{this.authService.usuario.nombre|split:' ':0}}
            </a>

            <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
              <button (click)="perfil()" class="dropdown-item" type="submit" data-toggle="collapse"
                      data-target=".navbar-collapse.show">Perfil
              </button>
              <button (click)="logout()" class="dropdown-item" type="submit" data-toggle="collapse"
                      data-target=".navbar-collapse.show">Cerrar sesión
              </button>
            </div>
          </li>
        </ng-template>

      </ul>
      <div class="mr-2 ml-2" style="color: white;white-space:nowrap" (click)="showCart()">
        <button class="d-block d-lg-none btn btn-info"><i
          class="fa fa-shopping-cart fa-lg red-cart"></i> {{items}} </button>
        <button class="d-none d-lg-block btn btn-info"><i
          class="fa fa-shopping-cart fa-lg red-cart"></i>Carrito: {{items}}
        </button>
      </div>
    </div>
  </div>
</nav>-->