<footer class="footer rounded-top text-center mt-5 pt-2" style="background-color: #004d9e;">
    <div class="container text-white">
        
            &copy; Manuel Melero <a href="tel:+34649627122" class="text-white">+34 649 62 71 22</a>
        
        <div class="row">
            <div class="col-12 text-white ">
                <a class="text-white" routerLink="/aviso_legal">Aviso Legal</a> |
                <a class="text-white" routerLink="/privacidad">Privacidad</a> |
                <a class="text-white" routerLink="/cookies">Cookies</a>
            </div>
        </div>
    </div>
</footer>