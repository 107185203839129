import {Component, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {Contact} from '../../entities/Contact';
import {ContactService} from '../../services/contact.service';
import {ToastService} from '../../services/toast.service';
import {Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  tittle="Contacto";
  text="¡Escribenos y te responderemos!";

  contact: Contact = new Contact();

  constructor(private contactService: ContactService, private router: Router, private spinner: NgxSpinnerService) {
  }

  ngOnInit() {
    this.spinner.hide();
  }

  enviarMensaje(contactForm: NgForm) {
    if (contactForm.valid) {
      this.spinner.show();

      this.contactService.createContact(this.contact).subscribe((data) => {
        ToastService.succes(data.msg);
        this.spinner.hide();
        this.contact = new Contact();
      });
    }

  }
}
