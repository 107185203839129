import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Usuario} from '../entities/usuarios';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import jwt_decode from "jwt-decode";

@Injectable({
  providedIn: 'root'
})
export class AuthService {


  private _usuario: Usuario;
  private _token: string;

  constructor(private http: HttpClient) {
  }

  public get usuario(): Usuario {
    if (this._usuario != null) {
      return this._usuario;
    } else if (this._usuario == null && sessionStorage.getItem('usuario') != null) {
      this._usuario = JSON.parse(sessionStorage.getItem('usuario')) as Usuario;
      return this._usuario;
    }
    return new Usuario();
  }

  public get token(): string {
    if (this._token != null) {
      return this._token;
    } else if (this._token == null && sessionStorage.getItem('token') != null) {
      this._token = sessionStorage.getItem('token');
      return this._token;
    }
    return null;
  }

  login(usuario: Usuario): Observable<any> {
    const urlEndpoint = environment.backend + '/login';
    let user = {
      username: usuario.email,
      password: usuario.password
    };
    return this.http.post<any>(urlEndpoint, user);
  }

  guardarUsuario(accessToken: string): void {
    const payload = this.obtenerDatosToken(accessToken);
    this._usuario = new Usuario();
    this._usuario.id = payload.user.id;
    this._usuario.nombre = payload.user.nombre;
    this._usuario.role = payload.user.roles;
    this._usuario.email = payload.user.email;
    sessionStorage.setItem('usuario', JSON.stringify(this._usuario));
  }

  guardarToken(accessToken: string): void {
    this._token = accessToken;
    sessionStorage.setItem('token', accessToken);
  }

  obtenerDatosToken(accessToken: string): any {
    if (accessToken != null) {
      return jwt_decode(accessToken);
    }
    return null;
  }

  isAuthenticated(): boolean {
    if (this.token==null)
      return false;
    const payload = this.obtenerDatosToken(this.token);
    console.log(JSON.stringify(payload));
    return payload != null && payload.user.nombre && payload.user.nombre.length > 0;
  }

  hasRole(role: string): boolean {
    console.log(this.usuario);
    return this.usuario.role.includes(role);
  }

  logout(): void {
    this._token = null;
    this._usuario = null;
    sessionStorage.clear();
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('usuario');
  }

  getUsuario() {
    const httpHeaders = new HttpHeaders(
      {
        'Content-Type': 'application/json'
      }
    );
    return this.http.get<any>(environment.backend + '/usuarios/' + this._usuario.id,
      {headers: this.agregarToken(httpHeaders)});
  }

  private agregarToken(httpHeaders: HttpHeaders) {
    const token = this.token;
    if (token != null) {
      return httpHeaders.append('Authorization', token);
    }
    return httpHeaders;
  }

  recovery(mail: string) {
    let data = {
      email: mail
    }
    return this.http.post(environment.backend + '/login/recovery/', data);
  }
}
