<div class="container">


    <h2>Comprar en nuestra web es realmente muy fácil</h2>
    <p>¿Quieres saber cómo? ¡Te enseñamos! <span>&#8595;</span></p>

    <p>Para visitar nuestra web no hace falta estar registrado o haber iniciado sesión.</p>
    <p>Puedes navegar tranquilamente y visualizar cada uno de nuestros productos, todo hechos con aloe vera ecológica pero sobretodo con mucho cariño, eso te lo aseguramos.
    </p>
    <p>¿Te ha gustado algún producto?</p>
    <p>No dudes en comprarlo, porque entonces... ¡&#9829;Te enamorarás&#9829;!</p>

    <p>El proceso de compra es muy sencillo y solo tienes que seguir los pasos indicados a continuación:</p>
    <ol>
        <li>Si es la primera vez que visitas nuestra web (qué alegría poder verte por aquí), tendrás que registrarte rellenando este pequeño formulario. <a routerLink="/register" target="_blank">¡Registrate!</a></li>
        <li>Una vez finalizado el formulario de registro debes ir a tu correo, verás nuestro primer correo (el comienzo de una gran amistad <span>&#9787;</span>), allí tendrás un enlace para verificar tu cuenta.
            <br>¿Porqué hacemos esto?
            <br>Por un motivo muy sencillo verificar que el correo sea el correcto. Imagina que le llegan tus pedidos a otra persona.
        </li>
        <li>Una vez finalizamos el paso anterior, ¡¡Ya estamos listos para <a routerLink="/login" target="_blank">iniciar
      sesión</a> y comprar!!
        </li>
        <li>¡Llegados a este punto queda muy poquito! Selecciona tus productos desde la pantalla principal y verás como estos se añaden automáticamente a tu carrito de la compra.
            <br>Cuando hayas terminado podrás acceder al mismo y hacer una revisión de todo lo seleccionado.
            <br>Pero, ¿dónde encontramos el carrito de la compra? Siempre en la parte superior derecha de esta web, ¡No tiene perdida!
        </li>
        <li>¿Has revisado tus pedido y todo esta correcto?</li>
        <li>Dale al botón de pagar y recibirás un correo electrónico con los datos del pedido y el IBAN donde deberás hacer una transferencia bancaria. <br>Estamos trabajando duramente y dentro de muy poquito podrás pagar desde esta misma web mediante el
            uso de tarjetas bancarias ya sean Visa o Mastercard.
            <img src="/assets/images/tarjetas.png" alt="Tarjetas bancarias" width="84px" height="32.7px">
        </li>
        <li>¿Has seguido todos los pasos? <br>¡Perfecto! Ahora solo toca esperar y en breve recibirás en tu casa los productos, esperamos que los disfrutes, tanto o más que nosotros.
        </li>
    </ol>
    <p>Si despues de todo tienes cualquier duda o sugerencia no dudes en ponerte en contacto con nosostros, estaremos encantado de ayudarte.</p>


</div>