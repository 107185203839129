import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-enchufe-wifi',
  templateUrl: './enchufe-wifi.component.html',
  styleUrls: ['./enchufe-wifi.component.scss']
})
export class EnchufeWifiComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
