<P></P>
<div class="container" align="center">
    <h1>Tira led con detección de movimiento</h1>
    <p>
        Una de las cosas que he montado en mi casa y que ha sido un gran acierto. Muy cómodo y he ahorrado en luz.
        <br>He montado unas luces led con detección de movimiento. Las he instalado en la cocina ya que es una zona de mucho tráfico en una casa. Y suelen pasar dos cosas o se queda siempre la luz encendida o te pasas el día enciendo y apagando el fluorescente
        con el gasto que ello conlleva.
    </p>
    <p>
        Actualmente tengo programadas las luces led que cuando detectan movimiento permanecen encendidas durante un minuto, esto es programable, a continuación, os explicare como realizar el montaje y la instalación. Algo relativamente fácil de instalar y muy
        útil.
    </p>
    <p>
        <strong>Material Necesario:</strong>
    </p>
    <p aling="center">
        <iframe style="width:120px;height:240px;" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//rcm-eu.amazon-adsystem.com/e/cm?lt1=_blank&amp;bc1=000000&amp;IS2=1&amp;bg1=FFFFFF&amp;fc1=000000&amp;lc1=0000FF&amp;t=tecnologiad0c-21&amp;language=es_ES&amp;o=30&amp;p=8&amp;l=as4&amp;m=amazon&amp;f=ifr&amp;ref=as_ss_li_til&amp;asins=B00HSF65MC&amp;linkId=7cfa0fce93f40550a70f5331c0c22b7c"></iframe>
        <iframe style="width:120px;height:240px;" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//rcm-eu.amazon-adsystem.com/e/cm?lt1=_blank&amp;bc1=000000&amp;IS2=1&amp;bg1=FFFFFF&amp;fc1=000000&amp;lc1=0000FF&amp;t=tecnologiad0c-21&amp;language=es_ES&amp;o=30&amp;p=8&amp;l=as4&amp;m=amazon&amp;f=ifr&amp;ref=as_ss_li_til&amp;asins=B079D3DCRT&amp;linkId=2d45ed8e25601d3aebc03de462895d02"></iframe>
        <iframe style="width:120px;height:240px;" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//rcm-eu.amazon-adsystem.com/e/cm?lt1=_blank&amp;bc1=000000&amp;IS2=1&amp;bg1=FFFFFF&amp;fc1=000000&amp;lc1=0000FF&amp;t=tecnologiad0c-21&amp;language=es_ES&amp;o=30&amp;p=8&amp;l=as4&amp;m=amazon&amp;f=ifr&amp;ref=as_ss_li_til&amp;asins=B07WWK2HFN&amp;linkId=2ea500db349822f888430e1f5ba07650"></iframe>
        <iframe style="width:120px;height:240px;" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//rcm-eu.amazon-adsystem.com/e/cm?lt1=_blank&amp;bc1=000000&amp;IS2=1&amp;bg1=FFFFFF&amp;fc1=000000&amp;lc1=0000FF&amp;t=tecnologiad0c-21&amp;language=es_ES&amp;o=30&amp;p=8&amp;l=as4&amp;m=amazon&amp;f=ifr&amp;ref=as_ss_li_til&amp;asins=B00IJCW82Y&amp;linkId=59411c8aa979c26f8dadc45a7112c94f"></iframe>
    </p>
    <p>
        Te aconsejo que primero hagas las conexiones de manera momentánea e experimental, para ver que has realizado correctamente todas las conexiones.
    </p>
    <p>
        El detector de movimiento va conectado directamente a 220v y las luces led van a 12v, por tanto, lo que primero tenemos que conectar es el detector de movimiento a la corriente eléctrica que es lo que nos hará de interruptor, de ahí pasaremos al transformador
        y del transformador a las luces led.
    </p>
    <p>
        <strong>Primer paso detector de movimiento</strong>
    </p>
    <p>
        <img src="/assets/images/Blog/TiraLed/detectorconexion-1.jpg">
    </p>
    <p>
        Cable azul del enchufe al cable azul del detector y cable marrón del enchufe al cable marrón del detector de movimiento. Cuando ya hagamos el montaje en el sitio definitivo en lugar de poner el enchufe podemos conectar los cables directamente en una toma
        de luz.
    </p>
    <p>
        A continuación, toca conectar el transformador
    </p>
    <p>
        <img src="/assets/images/Blog/TiraLed/transformadorconexion.jpg">
    </p>
    <p>
        En el transformador tenemos dos zonas de conexión una que pone input y otra que pone output.
    </p>
    <p>
        Ahora la conexión que nos interesa es el input y conectaremos un cable de color azul, desde la misma conexión que hemos realizado antes del enchufe del cable azul al positivo del transformador. Y el cable rojo al negativo.
    </p>
    <p>
        Por último solo nos falta conectar las luces led.
    </p>
    <p>
        <img src="/assets/images/Blog/TiraLed/ledconexion.jpg">
    </p>
    <p>
        Cable rojo de las luces led al positivo de la zona output del transformador y cable negro al negativo de la zona output del transformador.
    </p>
    <p>
        Ya tenemos todas las conexiones realizadas, ya ha llegado el momento de probar
    </p>
    <p>
        <img src="/assets/images/Blog/TiraLed/ledencendida.jpg">
    </p>
    <p>
        Nada más enchufar a la corriente eléctrica se nos enchufaran las luces led, para probar si realmente funciona el detector de movimiento tan solo hay que poner un trapo encima del detector y ver si al cabo de un rato se paran las luces, sacar el trapo
        y ver si las luces se vuelven a encender.
    </p>
    <p>
        Una vez visto que todo funciona correctamente es hora de programar el detector de movimiento.
    </p>
    <p>
        <img src="/assets/images/Blog/TiraLed/detector-1024x1024.jpg">
    </p>
    <p>
        En el detector podemos programar dos parámetros con un destornillador, en lux podemos programar con cuantos lúmenes queremos que se encienda la luz y en time programamos cuanto tiempo queremos que duren las luces una vez no detecte movimiento.
    </p>
    <p>
        Una vez lo tengamos configurado de la manera correcta, podemos proceder a la instalación donde realmente van a estar instaladas las luces.
    </p>
    <p>
        A continuación, os dejo una foto de mi resultado final
    </p>
    <p>
        <img src="/assets/images/Blog/TiraLed/resulfinal.jpg">
    </p>
    <p>
        Para que no se vea la instalación el transformador y los cables hasta el enchufe pasan por dentro del armario.
    </p>
    <p>
        Espero que os haya sido de utilidad.
    </p>
    <p aling="center">
        <iframe style="width:120px;height:240px;" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//rcm-eu.amazon-adsystem.com/e/cm?lt1=_blank&amp;bc1=000000&amp;IS2=1&amp;bg1=FFFFFF&amp;fc1=000000&amp;lc1=0000FF&amp;t=tecnologiad0c-21&amp;language=es_ES&amp;o=30&amp;p=8&amp;l=as4&amp;m=amazon&amp;f=ifr&amp;ref=as_ss_li_til&amp;asins=B00HSF65MC&amp;linkId=7cfa0fce93f40550a70f5331c0c22b7c"></iframe>
        <iframe style="width:120px;height:240px;" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//rcm-eu.amazon-adsystem.com/e/cm?lt1=_blank&amp;bc1=000000&amp;IS2=1&amp;bg1=FFFFFF&amp;fc1=000000&amp;lc1=0000FF&amp;t=tecnologiad0c-21&amp;language=es_ES&amp;o=30&amp;p=8&amp;l=as4&amp;m=amazon&amp;f=ifr&amp;ref=as_ss_li_til&amp;asins=B079D3DCRT&amp;linkId=2d45ed8e25601d3aebc03de462895d02"></iframe>
        <iframe style="width:120px;height:240px;" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//rcm-eu.amazon-adsystem.com/e/cm?lt1=_blank&amp;bc1=000000&amp;IS2=1&amp;bg1=FFFFFF&amp;fc1=000000&amp;lc1=0000FF&amp;t=tecnologiad0c-21&amp;language=es_ES&amp;o=30&amp;p=8&amp;l=as4&amp;m=amazon&amp;f=ifr&amp;ref=as_ss_li_til&amp;asins=B07WWK2HFN&amp;linkId=2ea500db349822f888430e1f5ba07650"></iframe>
        <iframe style="width:120px;height:240px;" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//rcm-eu.amazon-adsystem.com/e/cm?lt1=_blank&amp;bc1=000000&amp;IS2=1&amp;bg1=FFFFFF&amp;fc1=000000&amp;lc1=0000FF&amp;t=tecnologiad0c-21&amp;language=es_ES&amp;o=30&amp;p=8&amp;l=as4&amp;m=amazon&amp;f=ifr&amp;ref=as_ss_li_til&amp;asins=B00IJCW82Y&amp;linkId=59411c8aa979c26f8dadc45a7112c94f"></iframe>
    </p>
</div>