import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-beok-wifi',
  templateUrl: './beok-wifi.component.html',
  styleUrls: ['./beok-wifi.component.scss']
})
export class BeokWifiComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
