<div class="container">

    <p>En este espacio, el USUARIO, podrá encontrar toda la información relativa a los términos y condiciones legales que definen las relaciones entre los usuarios y nosotros como responsables de esta web. Como usuario, es importante que conozcas estos términos
        antes de continuar tu navegación. Tecnologiadecero.com como responsable de esta web, asume el compromiso de procesar la información de nuestros usuarios y clientes con plenas garantías y cumplir con los requisitos nacionales y europeos que regulan
        la recopilación y uso de los datos personales de nuestros usuarios. Esta web, por tanto, cumple rigurosamente con el RGPD (REGLAMENTO (UE) 2016/679 de protección de datos) y la LSSI-CE la Ley 34/2002, de 11 de julio, de servicios de la sociedad
        de la información y de comercio electrónico.
    </p>
    <h2><span style="font-size: 12px">► </span>CONDICIONES GENERALES DE USO</h2>
    <P>Las presentes Condiciones Generales regulan el uso (incluyendo el mero acceso) de las páginas de la web, integrantes del sitio web de tecnologiadecero.com incluidos los contenidos y servicios puestos a disposición en ellas. Toda persona que acceda
        a la web, tecnologiadecero.com (“Usuario”) acepta someterse a las Condiciones Generales vigentes en cada momento del portal tecnologiadecero.com.
    </P>
    <h2><span style="font-size: 12px">► </span>PRECIOS Y PAGO</h2>
    <p>Los precios exhibidos en el Sitio Web son los finales, en Euros (€) e incluyen los impuestos, salvo que, por exigencia legal, especialmente en lo relativo al IVA, se señale y aplique cuestión distinta.
    </p>
    <p>Los precios pueden cambiar en cualquier momento, pero los posibles cambios no afectarán a los pedidos o compras con respecto a los que el Usuario ya haya recibido una confirmación de pedido.
    </p>
    <p>Los medios de pago aceptados serán: Tarjeta de crédito o débito, transferencia bancaria o contra reembolso
    </p>
    <p>Las tarjetas de crédito estarán sujetas a comprobaciones y autorizaciones por parte de la entidad bancaria emisora de las mismas, si dicha entidad no autorizase el pago, no será responsable por ningún retraso o falta de entrega y no podrá formalizar
        ningún contrato con el Usuario.
    </p>
    <p>En todo caso, al hacer clic en “Comprar" el Usuario confirma que el método de pago utilizado es suyo.
    </p>
    <!--<h2><span style="font-size: 12px">► </span>Envio</h2>
    <p>Una vez realizado el pedido, este lo recibirá en un máximo de 4 días laborables.
    </p>
    <p>Si el importe del pedido no es superior a 45€ se cobrarán 5€ de gastos de envió.
    </p>
    <p>Los pedidos pagados por transferencia bancaria, el plazo de envió no empezara a contar, hasta haber recibido el comprobante de esta.
    </p>-->
    <h2><span style="font-size: 12px">► </span>Devoluciones</h2>
    <p>La Ley que regula las devoluciones en compras por internet dice que cuando compras a distancia dispones de 14 días naturales para decidir si te quedas con el producto o ejerces tu derecho de devolución.
    </p>
    <p>Por tanto una vez comprado un pack de horas dispones de catorce días para devolver el pack siempre y cuando no se haya hecho uso del mismo.</p>
    <p>El pack de horas tiene una vigencia de un año, las horas se han de haber consumido antes de que pase un año desde la fecha de compra.</p>

    <h2><span style="font-size: 12px">► </span>DATOS PERSONALES QUE RECABAMOS Y CÓMO LO HACEMOS
    </h2>
    Leer Política de Privacidad
    <h2><span style="font-size: 12px">► </span> COMPROMISOS Y OBLIGACIONES DE LOS USUARIOS
    </h2>
    <p>
        El Usuario queda informado, y acepta, que el acceso a la presente web no supone, en modo alguno, el inicio de una relación comercial con tecnologiadecero.com. De esta forma, el usuario se compromete a utilizar el sitio Web, sus servicios y contenidos
        sin contravenir la legislación vigente, la buena fe y el orden público.
    </p>
    <p>Queda prohibido el uso de la web, con fines ilícitos o lesivos, o que, de cualquier forma, puedan causar perjuicio o impedir el normal funcionamiento del sitio web. Respecto de los contenidos de esta web, se prohíbe:
    </p>
    <p>
        - Su reproducción, distribución o modificación, total o parcial, a menos que se cuente con la autorización de sus legítimos titulares.
        <br> - Cualquier vulneración de los derechos del prestador o de los legítimos titulares
        <br> - Su utilización para fines comerciales o publicitarios.
        <p>
            En la utilización de la web, tecnologiadecero.com, el Usuario se compromete a no llevar a cabo ninguna conducta que pudiera dañar la imagen, los intereses y los derechos de tecnologiadecero.com o de terceros o que pudiera dañar, inutilizar o sobrecargar
            el portal (tecnologiadecero.com) o que impidiera, de cualquier forma, la normal utilización de la web. No obstante, el Usuario debe ser consciente de que las medidas de seguridad de los sistemas informáticos en Internet no son enteramente
            fiables y que, por tanto tecnologiadecero.com no puede garantizar la inexistencia de virus u otros elementos que puedan producir alteraciones en los sistemas informáticos (software y hardware) del Usuario o en sus documentos electrónicos y
            ficheros contenidos en los mismos.
        </p>
        <h2><span style="font-size: 12px">► </span> MEDIDAS DE SEGURIDAD
        </h2>
        <p>
            Los datos personales comunicados por el usuario a tecnologiadecero.com pueden ser almacenados en bases de datos automatizadas o no, cuya titularidad corresponde en exclusiva a tecnologiadecero.com, asumiendo ésta todas las medidas de índole técnica, organizativa
            y de seguridad que garantizan la confidencialidad, integridad y calidad de la información contenida en las mismas de acuerdo con lo establecido en la normativa vigente en protección de datos.
        </p>
        <p>
            La comunicación entre los usuarios y tecnologiadecero.com utiliza un canal seguro, y los datos transmitidos son cifrados gracias a protocolos a https, por tanto, garantizamos las mejores condiciones de seguridad para que la confidencialidad de los usuarios
            esté garantizada.
        </p>
        <h2><span style="font-size: 12px">► </span> RECLAMACIONES
        </h2>
        <p>
            tecnologiadecero.com informa que existen hojas de reclamación a disposición de usuarios y clientes. El Usuario podrá realizar reclamaciones solicitando su hoja de reclamación o remitiendo un correo electrónico a email@manuelmelero.com indicando su nombre
            y apellidos, el servicio y/o producto adquirido y exponiendo los motivos de su reclamación.
        </p>
        <p>
            El usuario/comprador podrá notificarnos la reclamación, bien a través de correo electrónico a: email@manuelmelero.com, si lo desea adjuntando el siguiente formulario de reclamación: El servicio/producto: Adquirido el día: Nombre del usuario: Domicilio
            del usuario: Firma del usuario (solo si se presenta en papel): Fecha: Motivo de la reclamación:
        </p>
        <h2><span style="font-size: 12px">► </span> PLATAFORMA DE RESOLUCIÓN DE CONFLICTOS
        </h2>
        <p>
            Por si puede ser de tu interés, para someter tus reclamaciones puedes utilizar también la plataforma de resolución de litigios que facilita la Comisión Europea y que se encuentra disponible en el siguiente enlace: http://ec.europa.eu/consumers/odr/
        </p>
        <h2><span style="font-size: 12px">► </span> DERECHOS DE PROPIEDAD INTELECTUAL E INDUSTRIAL
        </h2>
        <p>
            En virtud de lo dispuesto en los artículos 8 y 32.1, párrafo segundo, de la Ley de Propiedad Intelectual, quedan expresamente prohibidas la reproducción, la distribución y la comunicación pública, incluida su modalidad de puesta a disposición, de la totalidad
            o parte de los contenidos de esta página web, con fines comerciales, en cualquier soporte y por cualquier medio técnico, sin la autorización de tecnologiadecero.com. El usuario se compromete a respetar los derechos de Propiedad Intelectual
            e Industrial titularidad de tecnologiadecero.com.
        </p>
        <h2><span style="font-size: 12px">► </span> ENLACES EXTERNOS
        </h2>
        <p>
            Las páginas de la web tecnologiadecero.com, podría proporcionar enlaces a otros sitios web propios y contenidos que son propiedad de terceros. El único objeto de los enlaces es proporcionar al Usuario la posibilidad de acceder a dichos enlaces. Tecnologiadecero.com
            no se responsabiliza en ningún caso de los resultados que puedan derivarse al Usuario por acceso a dichos enlaces.
        </p>
        <p>
            Asimismo, el usuario encontrará dentro de este sitio, páginas, promociones, programas de afiliados que acceden a los hábitos de navegación de los usuarios para establecer perfiles. Esta información siempre es anónima y no se identifica al usuario.
        </p>
        <p>
            La Información que se proporcione en estos Sitios patrocinado o enlaces de afiliados está sujeta a las políticas de privacidad que se utilicen en dichos Sitios y no estará sujeta a esta política de privacidad. Por lo que recomendamos ampliamente a los
            Usuarios a revisar detalladamente las políticas de privacidad de los enlaces de afiliado.
        </p>
        <p>
            El Usuario que se proponga establecer cualquier dispositivo técnico de enlace desde su sitio web al portal tecnologiadecero.com deberá obtener la autorización previa y escrita de tecnlogiadecero.com El establecimiento del enlace no implica en ningún caso
            la existencia de relaciones entre tecnologiadecero.com y el propietario del sitio en el que se establezca el enlace, ni la aceptación o aprobación por parte de tecnologiadecero.com de sus contenidos o servicios
        </p>
        <h2><span style="font-size: 12px">► </span> EXCLUSIÓN DE GARANTÍAS Y RESPONSABILIDAD
        </h2>
        <p>
            El Prestador no otorga ninguna garantía ni se hace responsable, en ningún caso, de los daños y perjuicios de cualquier naturaleza que pudieran traer causa de:
        </p>
        <p>
            La falta de disponibilidad, mantenimiento y efectivo funcionamiento de la web, o de sus servicios y contenidos;
        </p>
        <p>
            La existencia de virus, programas maliciosos o lesivos en los contenidos;
        </p>
        <p>
            El uso ilícito, negligente, fraudulento o contrario a este Aviso Legal;
        </p>
        <p>La falta de licitud, calidad, fiabilidad, utilidad y disponibilidad de los servicios prestados por terceros y puestos a disposición de los usuarios en el sitio web.
        </p>
        <p>El prestador no se hace responsable bajo ningún concepto de los daños que pudieran dimanar del uso ilegal o indebido de la presente página web.
        </p>
        <h2><span style="font-size: 12px">► </span>LEY APLICABLE Y JURISDICCIÓN
        </h2>
        <p>
            Con carácter general las relaciones entre tecnologiadecero.com con los Usuarios de sus servicios telemáticos, presentes en esta web se encuentran sometidas a la legislación y jurisdicción españolas y a los tribunales.
        </p>
        <h2><span style="font-size: 12px">► </span>CONTACTO</h2>
        <p>
            En caso de que cualquier Usuario tuviese alguna duda acerca de estas Condiciones legales o cualquier comentario sobre el portal tecnologiadecero.com, por favor diríjase a email@manuelmelero.com
        </p>
        <p>
            De parte del equipo que formamos Tecnologiadecero.com te agradecemos el tiempo dedicado en leer este Aviso Legal
        </p>
</div>