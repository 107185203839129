<P></P>
<div class="container" align="center">
    <h1>Enchufe Wifi Broadlink</h1>
    <p>
        <a href="https://amzn.to/3pl8Ubc"> <img src="/assets/images/Blog/EnchufeWifi/AmazonEnchufe.jpg" border="0" class="img-fluid"></a>
    </p>
    <p>
        Enchufe wifi de broadlink un elemento más que nos permite domotizar elementos de nuestro hogar de una forma fácil y económica.
    </p>
    <p>
        Con un enchufe wifi tipo este que os muestro de Broadlink, podemos medir el consumo de todo lo que haya enchufado en él. Pero además podemos tener el control desde cualquier lugar de lo que haya enchufado e incluso de programar su funcionamiento que se
        pare y enchufe a determinadas horas.
    </p>
    <p>
        Podemos programar que se enchufen las luces siempre a determinadas horas. O podemos hacer que al decirle a nuestro altavoz inteligente ya estoy en casa, que automáticamente se enchufe la luz. Estos enchufes nos proporcionan un sinfín de posibilidades
        para poder domotizar nuestro hogar un poco más.
    </p>
    <p>
        A continuación os voy a dar los pasos necesarios para enlazarlo con la aplicación de broadlink.
    </p>
    <div class="row">
        <div class="col-3">
            <img src="/assets/images/Blog/EnchufeWifi/1.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/EnchufeWifi/2.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/EnchufeWifi/3.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/EnchufeWifi/4.jpg" border="0" class="img-fluid">
        </div>
    </div>
    <div class="row">
        <div class="col-3">
            <img src="/assets/images/Blog/EnchufeWifi/5.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/EnchufeWifi/6.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/EnchufeWifi/7.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/EnchufeWifi/8.jpg" border="0" class="img-fluid">
        </div>
    </div>
    <div class="row">
        <div class="col-3">
            <img src="/assets/images/Blog/EnchufeWifi/10.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/EnchufeWifi/11.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/EnchufeWifi/12.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/EnchufeWifi/13.jpg" border="0" class="img-fluid">
        </div>
    </div>
    <div class="row">
        <div class="col-3">
            <img src="/assets/images/Blog/EnchufeWifi/14.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/EnchufeWifi/15.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/EnchufeWifi/16.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-3">
            <img src="/assets/images/Blog/EnchufeWifi/18.jpg" border="0" class="img-fluid">
        </div>
    </div>

    <p>Ya tenemos el dispositivo disponible en la lista de dispositivos de la aplicación.</p>
    <p>
        Ahora ya podemos decirle a nuestro altavoz inteligente por ejemplo “Alexa enciende el enchufe”, “Alexa para el enchufe”.
    </p>
    <p>
        O bien desde la aplicación lo podremos, programar, ver su consumo o enchufa o parar.
    </p>
    <p>
        Os adjunto también unos pantallazos de como se ve la pantalla del enchufe.
    </p>
    <div class="row">
        <div class="col-6">
            <img src="/assets/images/Blog/EnchufeWifi/Off.jpg" border="0" class="img-fluid">
        </div>
        <div class="col-6">
            <img src="/assets/images/Blog/EnchufeWifi/On.jpg" border="0" class="img-fluid">
        </div>
    </div>
    <p>
        <a href="https://amzn.to/3pl8Ubc"> <img src="/assets/images/Blog/EnchufeWifi/AmazonEnchufe.jpg" border="0" class="img-fluid"></a>
    </p>
</div>